import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import api from '../middleware/api';
import segment from '../middleware/segment';
import { routerMiddleware } from 'connected-react-router';
import parseLocationQuery from '../middleware/parseLocationQuery';
import { IS_SEGMENT_TRACKER_ENABLED } from 'src/const';

export const history = createBrowserHistory();

const args = [thunk, api, parseLocationQuery, routerMiddleware(history)];

if (IS_SEGMENT_TRACKER_ENABLED) {
  args.unshift(segment);
}

const finalCreateStore = compose(applyMiddleware(...args))(createStore);

export function configureStore(initialState) {
  return finalCreateStore(createRootReducer(history), initialState);
}

export const store = configureStore({});
