import React, { useState, useEffect } from 'react';
import AccessDenied from './components/AccessDenied';
import { Typography, Button } from '@material-ui/core';
import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';
import withStyles from '@material-ui/core/styles/withStyles';
import { useLocation } from 'react-router';

const styles = {
  paper: {
    paddingBottom: 10,
  },
  paragraph: { maxWidth: 560 },
  buttons: { display: 'flex', justifyContent: 'flex-end', gap: 10 },
  tryAgain: {
    textAlign: 'right',
    marginTop: 5,
    fontSize: 9,
  },
};

const PasswordExpired = ({ classes }) => {
  const [time, setTimer] = useState(0);

  const location = useLocation();
  const queryEntries = new URLSearchParams(location.search);

  const maxPasswordDays = queryEntries.get('maxPasswordDays');

  const callResetPassword = () => {
    setTimer(30);
    fetch('/api/v1/auth/reset-password')
      .then(() => {
        showSuccessPopupNotification('Email sent to reset your password!');
      })
      .catch(() => {
        showErrorPopupNotification('Failed to send email');
      });
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (time > 0) {
        setTimer(time - 1);
      } else {
        clearInterval(interval);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [time]);

  return (
    <AccessDenied classes={{ paper: classes.paper }} title='Password Expired'>
      <Typography className={classes.paragraph} variant={'body1'}>
        Your account password expired.{' '}
        {maxPasswordDays && (
          <>
            You are required to change your password every {maxPasswordDays}{' '}
            days.{' '}
          </>
        )}
        Please select “Reset Password” and check your email to reset your
        password.
      </Typography>
      <br />
      <div className={classes.buttons}>
        <a href='/api/v1/auth/logout'>
          <Button color='primary'>Return to login</Button>
        </a>
        <Button
          disabled={time > 0}
          variant='contained'
          onClick={callResetPassword}
          color='primary'
        >
          Reset Password
        </Button>
      </div>
      <Typography
        style={{
          opacity: time > 0 ? 1 : 0,
        }}
        className={classes.tryAgain}
        color='textSecondary'
        variant={'body2'}
      >
        You can try again after {time} seconds
      </Typography>
    </AccessDenied>
  );
};

export default withStyles(styles)(PasswordExpired);
