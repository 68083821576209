import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import FavoriteCard from './FavoriteCard';
import pluralize from 'pluralize';
import { push } from 'connected-react-router';

/**
 * STYLES
 */

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gap: 10,
    alignContent: 'flex-start',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#212121',
  },
}));

interface PropertyListsProps {
  propertyLists: {
    id: number;
    name: string;
    properties: number;
  }[];
}

const PropertyLists: React.FC<PropertyListsProps> = ({ propertyLists }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  if (!propertyLists.length) {
    return null;
  }
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Property Lists</Typography>
      {propertyLists.map((propertyList) => (
        <FavoriteCard
          key={`property-list-${propertyList.id}`}
          title={propertyList.name}
          subtitle={`${pluralize('property', propertyList.properties, true)}`}
          onClick={() => {
            window.analytics?.track('Property List Opened');
            dispatch(
              push(
                `properties?sidebar=property-list&list_id=${propertyList.id}`
              )
            );
          }}
        />
      ))}
    </div>
  );
};

PropertyLists.displayName = 'PropertyLists';

export default PropertyLists;
