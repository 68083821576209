export const requestGraphqlData = <V = unknown, D = unknown>(
  query: string,
  types: string[],
  dataToDispatch?: D,
  variables?: V,
  useAPI?: 'coreApi' | 'analyticsApi'
) => {
  return (dispatch) => {
    return dispatch({
      method: 'post',
      url: useAPI ? `/docs/${useAPI}` : '/graphqlQuery',
      send: { query, variables },
      types,
      dataToDispatch,
    });
  };
};

export const requestGraphqlDataUserGen = <V = unknown, D = unknown>(
  query: string,
  types: string[],
  dataToDispatch?: D,
  variables?: V
) => {
  return (dispatch) => {
    return dispatch({
      method: 'post',
      url: '/graphqlQueryUserGen',
      send: { query, variables },
      types,
      dataToDispatch,
    });
  };
};

export const requestFlattenedResults = (searchData, types) => {
  return (dispatch) => {
    return dispatch({
      method: 'post',
      url: '/search/flatten',
      send: searchData,
      types,
    });
  };
};
