import debounce from 'lodash/debounce';
import pick from 'lodash/pick';
import { IS_SEGMENT_TRACKER_ENABLED } from 'src/const';
import isEmulatedSessionChannel from 'src/utils/isEmulatedSessionChannel';

import { initSse } from 'src/products/auth/actions/auth';
import { handleNotification } from 'src/products/shell/redux/notifications/actions';
import { User } from '../reducers/user';
import { RootState } from 'react-redux';
import { PostHog } from 'posthog-js';

export const ME_REQUEST = 'ME_REQUEST';
export const ME_SUCCESS = 'ME_SUCCESS';
export const ME_FAILED = 'ME_FAILED';

export const PROFILE_FORM_CHANGED = 'PROFILE_FORM_CHANGED';
export const PROFILE_FORM_CHANGED_ERROR = 'PROFILE_FORM_CHANGED_ERROR';

export const PROFILE_UPDATE_REQUEST = 'PROFILE_UPDATE_REQUEST';
export const PROFILE_UPDATE_SUCCESS = 'PROFILE_UPDATE_SUCCESS';
export const PROFILE_UPDATE_FAILED = 'PROFILE_UPDATE_FAILED';

export const SYNC_PROFILE_FAILED = 'SYNC_PROFILE_FAILED';
export function syncProfile(
  dispatch,
  getState: () => RootState,
  fields?: (keyof User)[]
) {
  let data;

  if (!fields) {
    data = getState().user.profile.value;
  } else {
    data = pick(getState().user.profile.value, fields);
  }

  dispatch({
    types: [undefined, undefined, SYNC_PROFILE_FAILED],
    method: 'post',
    send: data,
    url: '/user/profile',
  });
}
export const syncProfileDebounce = debounce(syncProfile, 1000);

export const CHANGE_PROFILE = 'CHANGE_PROFILE';
export function changeProfile(profile) {
  return async (dispatch, getState: () => RootState) => {
    await dispatch({
      type: CHANGE_PROFILE,
      data: profile,
    });

    syncProfileDebounce(dispatch, getState);
  };
}

export function changeProfileForm(updatedFields) {
  return {
    type: PROFILE_FORM_CHANGED,
    updatedFields,
  };
}

export const PROFILE_FORM_RESET = 'PROFILE_FORM_RESET';
export function resetProfileForm(fields) {
  return {
    type: PROFILE_FORM_RESET,
    fields,
  };
}

export function changeProfileFormError(errorFields) {
  return {
    type: PROFILE_FORM_CHANGED_ERROR,
    errorFields,
  };
}

export function updateProfile() {
  return async (dispatch, getState: () => RootState) => {
    const {
      user: {
        profileForm: { value: profileData },
      },
    } = getState();

    const send = pick(profileData, ['firstName', 'lastName']);

    return dispatch({
      types: [
        PROFILE_UPDATE_REQUEST,
        PROFILE_UPDATE_SUCCESS,
        PROFILE_UPDATE_FAILED,
      ],
      send,
      method: 'post',
      url: '/user/profile',
    });
  };
}

export const REQUEST_SUBSCRIPTION = 'REQUEST_SUBSCRIPTION';
export const requestSubscription = () => {
  return {
    url: `/organizations/request-subscription`,
    method: 'get',
    types: [REQUEST_SUBSCRIPTION],
  };
};

export const ORG_MEMBERS_COUNT_REQUEST = 'ORG_MEMBERS_COUNT_REQUEST';
export const ORG_MEMBERS_COUNT_SUCCESS = 'ORG_MEMBERS_COUNT_SUCCESS';
export const ORG_MEMBERS_COUNT_FAILED = 'ORG_MEMBERS_COUNT_FAILED';
export const getOrgMembersCount = () => ({
  types: [
    ORG_MEMBERS_COUNT_REQUEST,
    ORG_MEMBERS_COUNT_SUCCESS,
    ORG_MEMBERS_COUNT_FAILED,
  ],
  url: `/organizations/users-count`,
  method: 'get',
});

export function setSegmentContext(user: User) {
  const { firstName, lastName, email, id, organizationId } = user;

  analytics?.identify(`${id}`, {
    firstName,
    lastName,
    email,
    id,
    company: {
      id: organizationId,
    },
  });
}

export function setIntercomContext(user: User) {
  const {
    firstName,
    lastName,
    email,
    createdAt,
    intercomHashedUserId,
    id,
    organizationId,
    organizationName,
  } = user;

  if (!intercomHashedUserId || !window.INTERCOM_APP_ID) {
    return;
  }

  window.Intercom &&
    window.Intercom('boot', {
      api_base: 'https://api-iam.intercom.io',
      app_id: window.INTERCOM_APP_ID,
      name: `${firstName} ${lastName}`,
      email,
      user_id: id,
      user_hash: intercomHashedUserId,
      created_at: createdAt,
      company: {
        company_id: `${organizationId}`,
        name: organizationName,
      },
    });
}

export function setPostHogContext(user: User, postHogClient: PostHog) {
  const { firstName, lastName, email, id, organizationId } = user;

  // Check if the feature flag "Restrict_Internal_Users" is enabled
  const isInternalUserRestricted = postHogClient?.isFeatureEnabled(
    'Restrict_Internal_Users'
  );

  if (isInternalUserRestricted) {
    // Opt-out of tracking and session recording for Internal users
    postHogClient?.opt_out_capturing();
    postHogClient?.stopSessionRecording();
    console.log('Tracking and session recording disabled for Internal user.');
  } else {
    // Reset the session before identifying a new user
    postHogClient?.reset();

    // Identify the user with the new distinct_id and user properties
    postHogClient?.identify(`${id}`, {
      email,
      firstName,
      lastName,
    });

    // Group the user with their organization
    postHogClient?.group('company', `${organizationId}`);

    console.log('Tracking and session recording enabled.');
  }
}

export const loadMe = (postHogClient?: PostHog) => {
  return async (dispatch, getState: () => RootState) => {
    const {
      user: {
        profile: {
          fetchStatus: { value: fetchStatus },
        },
      },
    } = getState();

    if (fetchStatus === 'LOADED') {
      return;
    }

    const user: User = await dispatch({
      url: '/user/me',
      types: [ME_REQUEST, ME_SUCCESS, ME_FAILED],
    });

    isEmulatedSessionChannel.postMessage(user.isEmulatedSession);

    dispatch(
      initSse(user.id, (notification) =>
        dispatch(handleNotification(notification))
      )
    );

    if (IS_SEGMENT_TRACKER_ENABLED) {
      setSegmentContext(user);
      postHogClient && setPostHogContext(user, postHogClient);
      setIntercomContext(user);
    }
  };
};
