import { createBrowserHistory } from 'history';
import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import createRootReducer from '../reducers';
import api from '../middleware/api';
// import segment from '../middleware/segment';
import { composeWithDevTools } from 'redux-devtools-extension';
import { routerMiddleware } from 'connected-react-router';
import parseLocationQuery from '../middleware/parseLocationQuery';
// import { IS_SEGMENT_TRACKER_ENABLED } from 'src/const';

export const history = createBrowserHistory();

const args = [thunk, api, parseLocationQuery, routerMiddleware(history)];

// if (IS_SEGMENT_TRACKER_ENABLED) {
//   args.unshift(segment);
// }

const finalCreateStore = composeWithDevTools(applyMiddleware(...args))(
  createStore
);

export function configureStore(initialState) {
  const store = finalCreateStore(createRootReducer(history), initialState);

  if (module.hot) {
    // Enable Webpack hot module replacement for reducers
    module.hot.accept('../reducers', () => {
      const nextRootReducer = require('../reducers');
      store.replaceReducer(nextRootReducer);
    });
  }

  return store;
}

export const store = configureStore({});
