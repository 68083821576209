require('smoothscroll-polyfill').polyfill();
import { isAllowed } from 'src/utils/routing/isAllowed';

import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import AccessDenied from 'src/products/shell/pages/AccessDenied/AccessDenied';

import App from '../../App';

import * as DataConnections from 'src/products/data-connections/pages';
import * as CoreExplore from 'src/products/core-prospect/pages';
import { GroupIcon } from 'src/components/ui/icons/GroupIcon';
import VpnKey from '@material-ui/icons/VpnKey';
import { RouteListType } from 'src/utils/routing';
import { CherreRoute } from 'src/utils/routing/CherreRoute';
import { dspRoutes } from 'src/products/data-submission-portal/routes';
import NotFound from 'src/products/shell/pages/NotFound';
import { DashboardLandingPageSource } from 'src/products/shell/redux/reducers/user';
import { DashboardRedirect } from 'src/products/core-bi/DashboardRedirect';
import { useSelector } from 'react-redux';
import { connectorRoutes } from 'src/products/connector/routes';
import { librariesRoutes } from 'src/products/models-library/routes';
// const MarketAnalytics = React.lazy(
//   () => import('src/products/core-prospect/MarketAnalytics.js')
// );
const Voyager = React.lazy(() => import('src/components/Voyager'));
const UserProfileLayout = React.lazy(
  () => import('src/products/profile/Layout')
);

const PersonalInfo = React.lazy(
  () => import('src/products/profile/PersonalInfo')
);

export const routesList: RouteListType = [
  {
    header: 'CoreSchema',
    permissions: ['CoreSchema'],
    routes: [
      {
        path: '/core-schema',
        component: () => <Voyager api='CoreAPI' />,
        menu: {
          icon: 'ico-voyager',
          group: 'CoreConnect',
        },
      },
    ],
  },
  {
    permissions: ['CoreAPI'],
    header: 'CoreAPI',
    routes: [
      {
        path: '/core-api/:pageId?',
        component: React.lazy(() => import('src/products/api-doc')),
        menu: {
          icon: 'ico_documents',
          group: 'CoreConnect',
        },
      },
    ],
  },
  {
    header: 'Analytics Schema',
    permissions: ['AnalyticsAPI'],
    routes: [
      {
        path: '/analytics-schema',
        component: () => <Voyager api='AnalyticsAPI' />,
        menu: {
          icon: 'ico-voyager',
          group: 'CoreConnect',
        },
      },
      {
        path: '/analytics-api/:pageId?',
        component: React.lazy(() => import('src/products/api-doc')),
        customHeader: 'Analytics API',
        menu: {
          icon: 'ico_documents',
          group: 'CoreConnect',
        },
      },
    ],
  },
  {
    header: 'Python Snippets',
    permissions: ['CoreAPI', 'AnalyticsAPI'],
    routes: [
      {
        path: '/python-snippets/:pageId?',
        component: React.lazy(() => import('src/products/api-doc')),
        customHeader: 'Python Snippets',
        menu: {
          icon: 'ico_documents',
          group: 'CoreConnect',
        },
      },
    ],
  },
  {
    permissions: ['CherreAdminReadOnly'],
    header: 'Organizations',
    routes: [
      {
        path: '/admin/migrations',
        component: React.lazy(
          () => import('src/products/admin/pages/Migration/List')
        ),
        customHeader: 'Migrations',
      },
      {
        path: '/admin/signups',
        component: React.lazy(
          () => import('src/products/admin/pages/Signup/List')
        ),
        customHeader: 'Signup Requests',
      },
      {
        path: '/admin/groups',
        component: React.lazy(
          () => import('src/products/admin/pages/Group/List')
        ),
      },
      {
        path: '/admin/groups/new',
        component: React.lazy(
          () => import('src/products/admin/pages/Group/Create')
        ),
      },
      {
        path: '/admin/groups/:group_id/permissions',
        component: React.lazy(
          () => import('src/products/admin/pages/Group/Permissions')
        ),
      },
      {
        path: '/admin/groups/:id',
        component: React.lazy(
          () => import('src/products/admin/pages/Group/Edit')
        ),
      },
      {
        path: '/admin/organizations',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/List')
        ),
      },
      {
        path: '/admin/organizations/new',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Create')
        ),
      },
      {
        path: '/admin/organizations/:id',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Edit')
        ),
      },
      {
        path: '/admin/organizations/:id/configuration/:schemaName?',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Configuration')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/permissions',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Permissions')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/dashboards',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Dashboards')
        ),
      },
      {
        path: '/admin/organizations/:id/datasets',
        component: React.lazy(
          () => import('src/products/admin/pages/Dataset/List')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/datasets/new',
        component: React.lazy(
          () => import('src/products/admin/pages/Dataset/Create')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/datasets/:dataset_id/datatables',
        component: React.lazy(
          () => import('src/products/admin/pages/Datatable/List')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/datasets/:dataset_id/new',
        component: React.lazy(
          () => import('src/products/admin/pages/Datatable/Create')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/datasets/:id',
        component: React.lazy(
          () => import('src/products/admin/pages/Dataset/Edit')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/datasets/:dataset_id/datatables/:id',
        component: React.lazy(
          () => import('src/products/admin/pages/Datatable/Edit')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/datasets/:id/dashboards',
        component: React.lazy(
          () => import('src/products/admin/pages/Dataset/Dashboards')
        ),
      },
      {
        path: '/admin/organizations/:id/users',
        component: React.lazy(
          () => import('src/products/admin/pages/User/List')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/workspaces',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Workspaces')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/new',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Form')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/:id',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Form')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/:user_id/permissions',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Permissions')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/:user_id/product-access',
        component: React.lazy(
          () => import('src/products/admin/pages/User/ProductAccess')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/:user_id/workspaces',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Workspaces')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/:user_id/dashboards',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Dashboards/user')
        ),
      },
      {
        path: '/admin/organizations/:organization_id/users/:user_id/configuration/:schemaName?',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/User/Configuration')
        ),
      },
      {
        path: '/admin/global-settings',
        component: React.lazy(
          () => import('src/products/admin/pages/GlobalSettings')
        ),
        customHeader: 'Global Settings',
      },
      {
        path: '/admin/organizations/:organization_id/settings',
        component: React.lazy(
          () => import('src/products/admin/pages/OrganizationSettings')
        ),
      },
    ],
  },
  {
    permissions: ['Global'],
    routes: [
      {
        path: '/',
        component: () => {
          const orgProps = useSelector(
            (state) => state.user?.profile.value.organizationProps
          );

          const permissions = useSelector(
            (state) => state.user?.profile.value.permissions
          );

          const dashboardLandingPage = orgProps?.dashboard_landing_page;
          const dashboardSource =
            orgProps?.dashboard_landing_page_source ??
            DashboardLandingPageSource.LOOKER;

          if (dashboardLandingPage) {
            if (
              dashboardSource === DashboardLandingPageSource.POWERBI &&
              permissions?.includes('PowerBI')
            ) {
              return (
                <Redirect
                  to={`/reports/${dashboardLandingPage}?organizationDefaultDashboard=true`}
                />
              );
            }

            if (
              dashboardSource === DashboardLandingPageSource.LOOKER &&
              permissions?.includes('CoreBI')
            ) {
              return <Redirect to={`/dashboards/${dashboardLandingPage}`} />;
            }
          }

          return <Redirect to='/apps' />;
        },
        customHeader: 'Home',
      },
      {
        path: '/apps',
        component: React.lazy(() => import('src/products/shell/pages/Home')),
        customHeader: 'Home',
        menu: {
          icon: 'ico-dashboard',
        },
      },
      {
        path: '/empty-permissions',
        component: React.lazy(
          () => import('src/products/shell/pages/AccessDenied/EmptyPermissions')
        ),
        customHeader: 'Error',
      },
      {
        path: '/profile/personal',
        customHeader: 'Personal Info',
        component: () => (
          <UserProfileLayout>
            <PersonalInfo />
          </UserProfileLayout>
        ),
      },
    ],
  },
  ...librariesRoutes,
  {
    permissions: ['CoreProspect'],
    header: 'CoreExplore',
    routes: [
      {
        path: '/portfoliodeal/:id?',
        component: CoreExplore.DealPage,
      },
    ],
  },
  {
    permissions: ['CoreProspect'],
    header: 'CoreExplore',
    routes: [
      {
        path: '/properties/:id?',
        component: React.lazy(
          () => import('src/products/core-prospect/search-pages/USALot')
        ),
        menu: {
          icon: 'tab-navigator',
          title: 'Search',
          group: 'CoreExplore',
        },
      },
      {
        path: '/properties/:cherre_parcel_id/:id',
        component: CoreExplore.LotPage,
      },
      {
        path: '/owners/:ownerId/:ownerName',
        component: React.lazy(
          () => import('src/products/core-prospect/CompanyDetails')
        ),
      },
      {
        path: '/lists-and-searches',
        component: React.lazy(
          () => import('src/products/core-prospect/ListsAndSearches')
        ),
        menu: {
          icon: 'Bookmark',
          title: 'My Saved Searches & Lists',
        },
      },
    ],
  },
  {
    permissions: [
      'CherreAdmin',
      'CherreAdminReadOnly',
      'PowerBIAdmin',
      'UserManagement',
      'IDAdmin',
      'RoleAdmin',
      'ProductAdmin',
      'EmulateNonIDAdmin',
    ],
    routes: [
      {
        permissionHook: () => {
          const permissions = useSelector(
            (state) => state.user?.profile.value.permissions
          );

          return isAllowed(
            [
              'CherreAdmin',
              'CherreAdminReadOnly',
              'PowerBIAdmin',
              'UserManagement',
              'IDAdmin',
              'RoleAdmin',
              'ProductAdmin',
            ],
            permissions ?? []
          );
        },
        path: '/customerAdmin/users',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/User/List')
        ),
        customHeader: 'Users',
        menu: {
          icon: 'user-1',
          group: 'Management',
        },
      },
    ],
  },
  {
    permissions: [
      'CherreAdmin',
      'CherreAdminReadOnly',
      'PowerBIAdmin',
      'UserManagement',
      'IDAdmin',
      'RoleAdmin',
    ],
    routes: [
      {
        path: '/customerAdmin/users/new',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Form')
        ),
        customHeader: 'Users',
      },
      {
        path: '/customerAdmin/users/:id',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Form')
        ),
        customHeader: 'Users',
      },
    ],
  },
  {
    permissions: [
      'UserManagement',
      'PowerBIAdmin',
      'CherreAdmin',
      'ProductAdmin',
    ],
    routes: [
      {
        path: '/customerAdmin/users/:user_id/permissions',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/User/Permissions')
        ),
        customHeader: 'Users',
      },
      {
        path: '/customerAdmin/users/:user_id/product-access',
        component: React.lazy(
          () => import('src/products/admin/pages/User/ProductAccess')
        ),
      },
    ],
  },
  {
    permissions: ['UserManagement', 'PowerBIAdmin', 'CherreAdmin'],
    routes: [
      {
        path: '/customerAdmin/groups',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/Teams/TeamsList')
        ),
        customHeader: 'Groups',
        menu: {
          icon: GroupIcon,
          group: 'Management',
        },
      },
      {
        path: '/customerAdmin/groups/:group_id',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/Teams/TeamMembers')
        ),
        customHeader: 'Groups',
      },
      {
        path: '/customerAdmin/users/:user_id/dashboards',
        component: React.lazy(
          () => import('src/products/admin/pages/Organization/Dashboards/user')
        ),
        customHeader: 'Users',
      },
      {
        path: '/customerAdmin/users/:user_id/workspaces',
        component: React.lazy(
          () => import('src/products/admin/pages/User/Workspaces')
        ),
      },
      {
        path: '/customerAdmin/users/:user_id/permissions',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/User/Permissions')
        ),
        customHeader: 'Users',
      },
      {
        path: '/customerAdmin/users/:user_id/product-access',
        component: React.lazy(
          () => import('src/products/admin/pages/User/ProductAccess')
        ),
      },
      {
        path: '/customerAdmin/users/:user_id/configuration/:schemaName?',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/User/Configuration')
        ),
        customHeader: 'Users',
      },
    ],
  },
  {
    permissions: ['CoreBI'],
    header: 'CoreExplore',
    routes: [
      {
        path: '/dashboards/:dashboard_slug?',
        component: React.lazy(() => import('src/products/core-bi')),
        menu: {
          icon: 'Settings-2',
          title: 'Dashboards',
          group: 'CoreExplore',
        },
      },
    ],
  },
  {
    permissions: ['PowerBI', 'PowerBIAdmin'],
    header: 'CoreExplore',
    routes: [
      {
        path: '/reports/:report_id?',
        component: React.lazy(() => import('src/products/core-bi-v2')),
        menu: {
          icon: 'Settings-2',
          title: 'Dashboards',
          group: 'CoreExplore',
        },
      },
    ],
  },
  {
    permissions: ['DataConnections'],
    header: 'Lightning Connections',
    routes: [
      {
        path: '/data-connections/details/:dataset_id',
        component: DataConnections.DatasetDetails,
      },
      {
        path: '/data-connections',
        component: DataConnections.DatasetList,
        menu: {
          icon: 'API',
          title: 'Lightning Connections',
          group: 'CoreExplore',
        },
      },
    ],
  },
  {
    permissions: ['ApiKeyManagement'],
    header: 'API Keys',
    routes: [
      {
        path: '/customerAdmin/api-keys/:action?',
        component: React.lazy(
          () => import('src/products/customerAdmin/pages/ApiKey/List')
        ),
        menu: {
          icon: VpnKey,
          group: 'Management',
        },
      },
    ],
  },
  ...dspRoutes,
  ...connectorRoutes,
];

export const routesListFlat = routesList
  .map((r) =>
    r.routes.map((sr) => ({
      ...sr,
      permissions: r.permissions,
      customHeader: sr.customHeader || r.header,
    }))
  )
  .flat();

const Routes: React.FC = () => (
  <App>
    <Switch>
      <Route
        path='/notfound'
        component={React.lazy(
          () => import('src/products/shell/pages/NotFound')
        )}
      />
      <Route
        path='/login'
        component={React.lazy(() => import('src/products/auth/pages/Login'))}
      />
      <Route
        path='/disabled-user'
        component={React.lazy(
          () => import('src/products/shell/pages/AccessDenied/DisabledUser')
        )}
      />
      <Route
        path='/password-expired'
        component={React.lazy(
          () => import('src/products/shell/pages/AccessDenied/PasswordExpired')
        )}
      />
      <Route path='/accessDenied' component={AccessDenied} />
      <Route
        path='/organization-is-disabled'
        component={() => <>Organization is disabled</>}
      />
      <Route
        path='/dashboards/embed/:dashboard_slug'
        exact
        component={DashboardRedirect}
      />
      {routesListFlat.map((r) => (
        <CherreRoute
          key={r.path}
          path={r.path}
          permissions={r.permissions}
          customHeader={r.customHeader || ''}
          permissionHook={r.permissionHook}
          exact
          component={r.component}
        />
      ))}
      <Route path='*' component={NotFound} />
    </Switch>
  </App>
);

export default Routes;
