import axios from 'axios';
import { selectorFamily } from 'recoil';

const PREFIX = 'HOME_PAGE/';

export const dashboardThumbnailAtom = selectorFamily<string, string>({
  key: PREFIX + 'DASHBOARD_THUMBNAIL_ATOM',
  get: (id) => async () => {
    const response = await axios.get<string>(
      `api/v1/dashboard-thumbnail/${id}`
    );
    return response.data
      .replace(/height="(\d{3})"/, 'height="100"')
      .replace(/width="(\d{3})"/, 'width="203"');
  },
});
