export const namespace = 'search';
const ns = (name) => `${namespace}/${name}`;

export const GET_ENTITIES_COUNT_REQUEST = ns('SEARCH_ENTITIES_COUNT_REQUEST');
export const GET_ENTITIES_COUNT_SUCCESS = ns('SEARCH_ENTITIES_COUNT_SUCCESS');
export const GET_ENTITIES_COUNT_FAILED = ns('SEARCH_ENTITIES_COUNT_FAILED');

export const GET_ENTITIES_REQUEST = ns('SEARCH_ENTITIES_REQUEST');
export const GET_ENTITIES_SUCCESS = ns('SEARCH_ENTITIES_SUCCESS');
export const GET_ENTITIES_FAILED = ns('SEARCH_ENTITIES_FAILED');

export const CHANGE_COUNT = ns('CHANGE_COUNT');
export const CHANGE_OFFSET = ns('CHANGE_OFFSET');
export const CHANGE_PREVIEW_OFFSET = ns('CHANGE_PREVIEW_OFFSET');
export const SELECT_MAP_RESULT = ns('SELECT_MAP_RESULT');

// export const TABLE_RESULTS = ns('TABLE_RESULTS');

export const GET_COL_DEFINITIONS = ns('COL_DEFINITIONS_RESULTS');
export const GET_COL_DEFINITIONS_SUCCESS = ns('COL_DEFINITIONS_SUCCESS');
export const GET_COL_DEFINITIONS_FAILED = ns('COL_DEFINITIONS_FAILED');
