import React, { Suspense } from 'react';

import RootModal from 'src/components/modals';
import Notifications from './Notifications';

require('./less/App.less');

const App: React.FC<{ children: React.ReactNode }> = ({ children }) => (
  <Suspense fallback={<span />}>
    {children}
    <Notifications />
    <RootModal />
  </Suspense>
);

export default App;
