import { makeStyles, Typography } from '@material-ui/core';
import { push } from 'connected-react-router';
import React from 'react';
import { useDispatch } from 'react-redux';
import FEATURED_ARTICLES from './featuredArticlesData';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 28,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#212121',
    marginBottom: 13,
  },
  cardList: {
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, 315px)',
    gap: '10px 24px',
  },
  cardContainer: {
    padding: 10,
    borderRadius: 6,
    border: 'solid 1px #e0e0e0',
    backgroundColor: '#fff',
    cursor: 'pointer',
    display: 'grid',
    gap: 3,
  },
  cardTitle: {
    fontSize: 14,
    fontWeight: 600,
    color: '#212121',
  },
  cardSubtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: '#616161',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
}));

const FeaturedArticles: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Featured articles</Typography>
      <div className={classes.cardList}>
        {FEATURED_ARTICLES.map((article) => (
          <div
            className={classes.cardContainer}
            onClick={() => {
              window.analytics?.track('User Selects Featured Article');
              dispatch(push(article.url));
            }}
            key={article.url}
          >
            <Typography className={classes.cardTitle}>
              {article.title}
            </Typography>
            <Typography className={classes.cardSubtitle}>
              {article.subtitle}
            </Typography>
          </div>
        ))}
      </div>
    </div>
  );
};

FeaturedArticles.displayName = 'FeaturedArticles';

export default FeaturedArticles;
