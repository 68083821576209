import * as CommonActionTypes from '../actions/common';
import * as NOTIFICATION_TYPES from '../notifications/types';
import constants from 'src/const';

const initialState = {
  notifications: {
    value: [],
    fetchStatus: {},
    getMoreStatus: {},
    readStatus: {},
    totalCount: 0,
    unreadCount: 0,
  },
};

function notifications(state = initialState.notifications, action) {
  switch (action.type) {
    case CommonActionTypes.NOTIFY: {
      return {
        ...state,
        value: [action.notification, ...state.value],
        unreadCount: state.unreadCount + 1,
      };
    }
    case NOTIFICATION_TYPES.UNREAD_NOTIFICATIONS_COUNT_SUCCESS: {
      return {
        ...state,
        unreadCount: action.data.unreadCount,
      };
    }
    case NOTIFICATION_TYPES.READ_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        readStatus: {
          value: constants.LOADING,
        },
      };
    }
    case NOTIFICATION_TYPES.READ_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        value: state.value.map((notification) => ({
          ...notification,
          isViewed: true,
        })),
        unreadCount: action.data.unreadCount,
        readStatus: {
          value: constants.LOADED,
          message: '',
        },
      };
    }
    case NOTIFICATION_TYPES.READ_NOTIFICATIONS_FAILED: {
      const message = Array.isArray(action.error)
        ? action.error[0].message
        : action.error.message;

      return {
        ...state,
        readStatus: {
          value: constants.FAILED,
          message,
        },
      };
    }
    case NOTIFICATION_TYPES.GET_MORE_NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          value: constants.LOADING,
        },
      };
    }
    case NOTIFICATION_TYPES.GET_MORE_NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        value: state.value.concat(action.data.notifications || []),
        fetchStatus: {
          value: constants.LOADED,
          message: '',
        },
      };
    }
    case NOTIFICATION_TYPES.GET_MORE_NOTIFICATIONS_FAILED: {
      const message = Array.isArray(action.error)
        ? action.error[0].message
        : action.error.message;

      return {
        ...state,
        fetchStatus: {
          value: constants.FAILED,
          message,
        },
      };
    }
    case NOTIFICATION_TYPES.NOTIFICATIONS_REQUEST: {
      return {
        ...state,
        fetchStatus: {
          value: constants.LOADING,
        },
        value: [],
      };
    }
    case NOTIFICATION_TYPES.NOTIFICATIONS_SUCCESS: {
      return {
        ...state,
        value: action.data.notifications,
        totalCount: action.data.totalCount,
        unreadCount: action.data.unreadCount,
        fetchStatus: {
          value: constants.LOADED,
          message: '',
        },
      };
    }
    case NOTIFICATION_TYPES.NOTIFICATIONS_FAILED: {
      const message = Array.isArray(action.error)
        ? action.error[0].message
        : action.error.message;

      return {
        ...state,
        fetchStatus: {
          value: constants.FAILED,
          message,
        },
      };
    }
    default:
      return state;
  }
}

export default notifications;
