import React from 'react';
import { styled, Typography, ArrowForwardIcon } from '@cherre-frontend/ui';

/**
 * STYLES
 */
const Container = styled('div')`
  cursor: pointer;
  padding: 10px;
  border-radius: 6px;
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.2);
  background: ${({ theme }) => theme.palette.common.white};
  display: grid;
  grid-template-areas: ${() =>
    '"title title title . arrow"". . . . .""icon . subtitle subtitle subtitle"'};
  grid-template-columns: auto 10px 1fr 10px 32px;
  grid-template-rows: 32px 5px auto;
  align-items: center;
  border: 1px solid transparent;
  :hover {
    border: 1px solid ${({ theme }) => theme.palette.primary.main};
  }
`;

const Title = styled(Typography)`
  grid-area: title;
  color: ${({ theme }) => theme.palette.common.black};
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

const Arrow = styled('span')`
  grid-area: arrow;
  color: ${({ theme }) => theme.palette.primary.main};
  display: grid;
  place-items: center;
`;

const Icon = styled('span')`
  grid-area: icon;
  color: ${({ theme }) => theme.palette.primary.main};
  display: grid;
  place-items: center;
`;

const Subtitle = styled(Typography)`
  grid-area: subtitle;
  color: ${({ theme }) => theme.palette.grey[500]};
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
`;

export interface CardProps {
  hide?: boolean;
  title: string;
  subtitle: string;
  onClick: () => void;
  icon: React.ReactNode;
}

const Card: React.FC<CardProps> = (props) => {
  if (props.hide) {
    return null;
  }
  return (
    <Container onClick={props.onClick}>
      <Title>{props.title}</Title>
      <Arrow>
        <ArrowForwardIcon />
      </Arrow>
      <Icon>{props.icon}</Icon>
      <Subtitle>{props.subtitle}</Subtitle>
    </Container>
  );
};

export default Card;
