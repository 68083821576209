import * as types from './types';
import * as configDataTypes from '../filters-config-data/types';

const initialState = {
  errors: [],
  formData: {},
  pristine: true,
  editingEntityName: null,
  validation: [
    'lessThan',
    'lessThanOrEqual',
    'greaterThan',
    'greaterThanOrEqual',
    'positiveInteger',
    'price',
    'isEmail',
    'minLength',
    'maxLength',
    'phone',
  ],
};

export default function filterEditing(state = initialState, action) {
  switch (action.type) {
    case configDataTypes.SAVE_FILTER_FAILED:
    case configDataTypes.SAVE_FILTER_SET_FAILED:
    case configDataTypes.SAVE_FILTER_GROUP_FAILED: {
      return {
        ...state,
        errors: Array.isArray(action.error) ? action.error : [action.error],
      };
    }

    case configDataTypes.SAVE_FILTER_SUCCESS:
    case configDataTypes.SAVE_FILTER_SET_SUCCESS:
    case configDataTypes.SAVE_FILTER_GROUP_SUCCESS: {
      return {
        ...state,
        formData: {},
        pristine: true,
        errors: [],
      };
    }

    case types.START_EDITING: {
      return {
        ...state,
        formData: action.defaultFormData || {},
        errors: [],
        editingEntityName: action.editingEntityName,
      };
    }

    case types.CHANGE_FORM_DATA: {
      return {
        ...state,
        formData: {
          ...state.formData,
          ...action.formDataDiff,
        },
        pristine: false,
      };
    }

    default:
      return state;
  }
}
