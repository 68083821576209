import React from 'react';
import HOC from './HOC';
import AccessDenied from './components/AccessDenied';
import { Typography, Button } from '@material-ui/core';

export default HOC(({ admin }) => {
  return (
    <AccessDenied title='Access Denied'>
      <Typography variant={'body1'}>No access to this application.</Typography>
      <Typography variant={'body1'}>
        Please contact your organization representative.
      </Typography>
      <br />
      <br />
      <div>
        <a href={`mailto: ${admin.email}`}>
          <Button color='primary' variant='contained'>
            Get in touch
          </Button>
        </a>
        &nbsp;&nbsp;
        <a href='/api/v1/auth/logout'>
          <Button color='primary'>Return to login</Button>
        </a>
      </div>
    </AccessDenied>
  );
});
