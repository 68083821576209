import * as dev from './configureStore.dev';
import * as prod from './configureStore.prod';

let redux: {
  configureStore: typeof dev.configureStore;
  history: typeof dev.history;
  store: ReturnType<typeof dev.configureStore>;
};

if (process.env.NODE_ENV === 'development') {
  redux = dev;
} else if (process.env.NODE_ENV === 'test') {
  redux = dev;
} else {
  redux = prod;
}

export default redux;
