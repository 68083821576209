import { combineReducers } from 'redux';
import user from './user';
import auth from 'src/products/auth/reducers/auth';
import common from './common';
import adminPanel from 'src/products/admin/redux/reducers';
import coreProspect from 'src/products/core-prospect/redux/reducers';
import coreBI from 'src/products/core-bi/redux';
import reduxQueryReducer from 'src/redux-query/reducer';
import { connectRouter } from 'connected-react-router';
import routerHistory from './routerHistory';
import homepage from 'src/products/shell/pages/Home/redux/reducers';
import coreAPI from 'src/products/api-doc/redux/introspectionSchema/reducer.js';

const createRootReducer = (history) =>
  combineReducers({
    user,
    auth,
    common,
    coreProspect,
    adminPanel,
    coreBI,
    coreAPI,
    reduxSearch: reduxQueryReducer,
    router: connectRouter(history),
    routerHistory,
    homepage,
  });

export default createRootReducer;
