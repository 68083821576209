import pluralize from 'pluralize';
import { Collapse, styled } from '@material-ui/core';
import React from 'react';
import { Alert as MUIAlert } from '@material-ui/lab';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { requestSubscription } from 'src/products/shell/redux/actions/user';

/**
 * CONSTANTS
 */

const EXPIRATION_WARNING_PERIOD = 10;
const ON_SUBSCRIBE_CLICK_MSG =
  'Your request has been received. We will be in touch with you via email.';

/**
 * STYLES
 */

const Container = styled('div')({
  position: 'absolute',
  width: 'min(calc(100% - 48px), 1232px)',
  zIndex: 10,
});

const Subscribe = styled('div')({
  fontWeight: 700,
  cursor: 'pointer',
  marginLeft: 8,
  display: 'inline-block',
});

const Alert = styled(MUIAlert)({
  fontSize: 14,
  lineHeight: '14px',
  borderRadius: 10,
  borderTopRightRadius: 0,
  borderTopLeftRadius: 0,
  alignItems: 'center',
});

/**
 * UTILITIES
 */

const expiresAtSelector = (state: any) => {
  return state?.user?.profile?.value?.organizationProps?.plan_expires_at;
};

const checkExpiration = (expiresAt: string | null | undefined) => {
  if (!expiresAt) {
    return null;
  }
  const expiresAtDate = new Date(expiresAt);
  if (moment().isAfter(expiresAtDate)) {
    return 'expired';
  }
  const daysToExpire = moment(expiresAtDate).diff(Date.now(), 'days');
  if (daysToExpire === 0) {
    return 'today';
  }
  if (daysToExpire < EXPIRATION_WARNING_PERIOD) {
    return `in ${daysToExpire} ${pluralize('day', daysToExpire)}`;
  }
  return null;
};

const createAlertMsg = (expiration: string | null) => {
  if (expiration === 'expired') {
    return 'Attention! Your account is suspended.';
  }
  return `Heads up! Your plan is about to expire ${expiration}.`;
};

const createSubscribeButtonTitle = (expiration: string | null) => {
  if (expiration === 'expired') {
    return 'Re-Activate';
  }
  return 'Subscribe';
};

/**
 * MAIN COMPONENT
 */

const ExpirationAlert: React.FC = () => {
  const [isSubscribeClicked, toggleIsSubscribeClicked] = React.useState(false);
  const dispatch = useDispatch();
  const expiresAt = useSelector(expiresAtSelector);
  const expiration = checkExpiration(expiresAt);
  const alertMsg = createAlertMsg(expiration);
  const subscribeButtonTitle = createSubscribeButtonTitle(expiration);

  if (!expiration) {
    return null;
  }

  return (
    <Container>
      <Collapse in>
        <Alert
          variant='filled'
          severity={expiration === 'expired' ? 'error' : 'info'}
        >
          {!isSubscribeClicked ? alertMsg : ON_SUBSCRIBE_CLICK_MSG}
          {!isSubscribeClicked && (
            <Subscribe
              onClick={() => {
                dispatch(requestSubscription());
                toggleIsSubscribeClicked(true);
              }}
            >
              {subscribeButtonTitle}
            </Subscribe>
          )}
        </Alert>
      </Collapse>
    </Container>
  );
};

ExpirationAlert.displayName = 'ExpirationAlert';

export default ExpirationAlert;
