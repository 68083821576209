export const NOTIFY = 'NOTIFY';
export const GET_PIPL_CONTACT = 'GET_PIPL_CONTACT';

export function getPiplContact(query) {
  return {
    types: [GET_PIPL_CONTACT],
    url: '/pipl/get-contact',
    query,
  };
}

export function getOrganizationAdmin() {
  return async (dispatch) => {
    return dispatch({
      types: [undefined, undefined, undefined],
      url: '/get-organization-admin',
    });
  };
}
