import React from 'react';
import { Container, CssBaseline, makeStyles } from '@material-ui/core';
import * as Components from './HomePageComponents';
import { useSelector } from 'react-redux';

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gap: '60px',
  },
}));

const HomePage: React.FC = () => {
  const classes = useStyles();

  const isHomePageCherreGraphHidden = useSelector((state) =>
    Boolean(state.user.profile.value?.featureFlags?.isHomePageCherreGraphHidden)
  );

  return (
    <>
      <CssBaseline />
      <Container className={classes.container}>
        <Components.Alerts />
        <Components.HomePageHeader />
        <div />
        <Components.DashboardsSelector />
        <Components.PowerBIReportsSelector />
        <Components.DSPSection />
        <Components.ExploreFoundationData />
        <Components.DeveloperPortal />
        {!isHomePageCherreGraphHidden && <Components.CherreGraph />}
        <div />
      </Container>
    </>
  );
};

HomePage.displayName = 'HomePage';

export default HomePage;
