import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router';
import { ReportCard } from './ReportCard';
import { AutoSizer } from 'react-virtualized';
import { Skeleton } from '@material-ui/lab';
import { Panel } from '@cherre-frontend/ui';

import { useDashboardScroll } from '../DashboardsSelector/useDashboardScroll';

import {
  Container,
  Title,
  ViewAll,
  ArrowLeft,
  ArrowRight,
  List,
  Grid,
  NoReportsCard,
} from './styles';

import {
  DataFetchingError,
  FetchError,
  getSelector,
  useCherreValue,
  useIsSuspended,
} from '@cherre-frontend/data-fetching';
import { PowerBIReport } from 'src/products/core-bi-v2/types';
import { DefaultErrorBoundary } from 'src/products/shell/DataFetchingConfig';
import { AxiosResponse } from 'axios';
import { usePermission } from 'src/hooks';
import { defaultReportSelector } from 'src/products/core-bi-v2/recoil';
import { sortPowerBIReports } from 'src/products/core-bi-v2/utils';

export const reportsSelector = getSelector<{ reports: PowerBIReport[] }>({
  url: `/api/v1/powerbi/reports`,
});

const PowerBIReportsSelector: React.FC = () => {
  const hasAccess = usePermission('PowerBI');

  if (!hasAccess) {
    return null;
  }

  if (usePermission('CoreBI')) {
    return null;
  }

  const apiDefaultReportId = useCherreValue(defaultReportSelector);

  const reportsResponse = useCherreValue(reportsSelector);
  const sortedReports = useMemo(() => {
    if (!reportsResponse?.reports) {
      return [];
    }

    const { reports } = reportsResponse;
    return sortPowerBIReports(reports, apiDefaultReportId);
  }, [reportsResponse?.reports]);

  const history = useHistory();
  const suspended = useIsSuspended();

  const { listRef, scroll, onClickScroll } = useDashboardScroll(
    suspended,
    'User Carousels Reports'
  );

  const columnCount = suspended ? 15 : sortedReports?.length || 1;

  return (
    <Container>
      <Title variant='h2'>My Dashboards</Title>
      {!suspended && !!sortedReports?.length && (
        <ViewAll
          onClick={() => {
            window.analytics?.track(
              'User Selects View Default on Power BI Reports'
            );
            history.push('reports');
          }}
        >
          View Default <ArrowForward fontSize='inherit' />
        </ViewAll>
      )}
      {scroll.left && (
        <ArrowLeft onClick={onClickScroll('left')} data-testid='arrow-left'>
          <ArrowBack />
        </ArrowLeft>
      )}
      {scroll.right && (
        <ArrowRight onClick={onClickScroll('right')} data-testid='arrow-right'>
          <ArrowForward />
        </ArrowRight>
      )}
      <List>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Grid
              ref={listRef}
              direction='horizontal'
              height={200}
              width={width}
              columnCount={columnCount}
              columnWidth={230}
              rowHeight={190}
              rowCount={1}
              cellRenderer={({ columnIndex, style }) => {
                let component = (
                  <Skeleton
                    variant='rect'
                    width={203}
                    height={180}
                    style={{
                      borderRadius: 6,
                    }}
                  />
                );

                if (!suspended && sortedReports) {
                  if (columnIndex === 0 && sortedReports?.length === 0) {
                    component = <NoReportsCard />;
                  } else {
                    component = <ReportCard {...sortedReports[columnIndex]} />;
                  }
                }

                return (
                  <div key={`dash-${columnIndex}`} style={style}>
                    {component}
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </List>
    </Container>
  );
};

PowerBIReportsSelector.displayName = 'HomePagePowerBIReportsSelector';

export default () => (
  <Panel
    errorFallback={(props) => {
      const error = props.error;

      if (error instanceof DataFetchingError) {
        if (error.originalError instanceof FetchError) {
          const response = error.originalError.error.response as AxiosResponse;
          if (
            response?.data?.errors?.some(
              (error) => error?.message === 'Missing Power BI configuration'
            )
          ) {
            return null;
          }
        }
      }

      return <DefaultErrorBoundary {...props} />;
    }}
    id='HomePagePowerBIReportsSelector'
  >
    <PowerBIReportsSelector />
  </Panel>
);
