import React from 'react';
import constants from 'src/const';
import last from 'lodash/last';
import * as NOTIFICATION_TYPES from './types';
import {
  showSuccessPopupNotification,
  showErrorPopupNotification,
} from 'src/products/shell/Notifications';

import notificationTemplates from 'src/components/Notifications/templates';

function getNotificationTemplate(notification) {
  const Template = notificationTemplates[notification.subCategory];

  if (Template) {
    return <Template notification={notification} />;
  } else {
    return <div>{notification.text}</div>;
  }
}

export function handleNotification(notification) {
  return (dispatch) => {
    if (notification.isPush) {
      if (notification.isError) {
        showErrorPopupNotification(
          '',
          0,
          getNotificationTemplate(notification)
        );
      } else {
        showSuccessPopupNotification(
          '',
          0,
          getNotificationTemplate(notification)
        );
      }
    }

    if (
      ['team-change-request', 'team-change-invite'].includes(
        notification.subCategory
      )
    ) {
      dispatch({
        type: 'CREATE_TEAM_CHANGE_SUCCESS',
        data: notification.data.request,
      });
    }

    if (
      [
        'team-change-reject-request-by-user',
        'team-change-reject-request-by-owner',
        'team-change-reject-invite-by-user',
        'team-change-reject-invite-by-owner',
      ].includes(notification.subCategory)
    ) {
      dispatch({
        type: 'REJECT_TEAM_CHANGE_SUCCESS',
        data: {
          _id: notification.data.request.id,
        },
      });
    }

    if (
      ['team-change-approve-request', 'team-change-approve-invite'].includes(
        notification.subCategory
      )
    ) {
      dispatch({
        type: 'APPROVE_TEAM_CHANGE_SUCCESS',
        updateUserTeam: true,
        data: notification.data.request,
      });
    }

    if (notification.subCategory === 'remove-from-team') {
      dispatch({
        type: 'CLEAR_USER_TEAM',
      });
    }

    if (notification.subCategory === 'user-left-your-team') {
      dispatch({
        type: 'USER_LEFT_TEAM',
        data: notification.data.user,
      });
    }
  };
}

export function readNotifications() {
  return async (dispatch) => {
    return dispatch({
      url: '/notifications/read/all',
      method: 'post',
      types: [
        NOTIFICATION_TYPES.READ_NOTIFICATIONS_REQUEST,
        NOTIFICATION_TYPES.READ_NOTIFICATIONS_SUCCESS,
        NOTIFICATION_TYPES.READ_NOTIFICATIONS_FAILED,
      ],
    });
  };
}

export function getUnreadNotificationsCount() {
  return async (dispatch, getState) => {
    const {
      common: { notifications },
    } = getState();

    if (
      [constants.LOADING, constants.LOADED].includes(
        notifications.fetchStatus.value
      )
    ) {
      return;
    }

    return dispatch({
      url: '/notifications/unread-count',
      method: 'get',
      types: [
        NOTIFICATION_TYPES.UNREAD_NOTIFICATIONS_COUNT_REQUEST,
        NOTIFICATION_TYPES.UNREAD_NOTIFICATIONS_COUNT_SUCCESS,
        NOTIFICATION_TYPES.UNREAD_NOTIFICATIONS_COUNT_FAILED,
      ],
    });
  };
}

export function getNotifications(category = 'All') {
  return async (dispatch) => {
    return dispatch({
      url: '/notifications',
      method: 'get',
      query: { category },
      types: [
        NOTIFICATION_TYPES.NOTIFICATIONS_REQUEST,
        NOTIFICATION_TYPES.NOTIFICATIONS_SUCCESS,
        NOTIFICATION_TYPES.NOTIFICATIONS_FAILED,
      ],
    });
  };
}

export function getMoreNotifications(category) {
  return async (dispatch, getState) => {
    const {
      common: { notifications },
    } = getState();

    if ([constants.LOADING].includes(notifications.fetchStatus.value)) {
      return;
    }

    const lastNotification = last(notifications.value);

    return dispatch({
      url: '/notifications',
      method: 'get',
      types: [
        NOTIFICATION_TYPES.GET_MORE_NOTIFICATIONS_REQUEST,
        NOTIFICATION_TYPES.GET_MORE_NOTIFICATIONS_SUCCESS,
        NOTIFICATION_TYPES.GET_MORE_NOTIFICATIONS_FAILED,
      ],
      query: {
        token: JSON.stringify([
          lastNotification.createdAt,
          lastNotification.id,
        ]),
        category,
      },
    });
  };
}
