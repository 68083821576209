import React from 'react';
import NotificationSystem from 'react-notification-system';

let notificationSystem;

const NotificationsContainer = () => (
  <NotificationSystem
    ref={(el) => {
      if (el) {
        notificationSystem = el;
      }
    }}
  />
);

export const showSuccessPopupNotification = (
  message = 'Success!',
  autoDismiss = 5,
  children
) => {
  notificationSystem.addNotification({
    message,
    level: 'success',
    autoDismiss,
    children,
  });
};

export const showErrorPopupNotification = (
  err = 'Something went wrong',
  autoDismiss = 5,
  children
) => {
  const message = Array.isArray(err) && err.length ? err[0].message : err;

  notificationSystem.addNotification({
    message,
    level: 'error',
    autoDismiss,
    children,
  });
};

export const showWarningPopupNotification = (message = 'Warning!') => {
  notificationSystem.addNotification({
    message,
    level: 'warning',
    autoDismiss: 10,
  });
};

export default NotificationsContainer;
