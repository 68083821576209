import { useCallback, useEffect, useState } from 'react';
import { Grid } from 'react-virtualized';

const scrollCallback = (currentRef: HTMLDivElement | undefined) => {
  const scrollLeft = currentRef?.scrollLeft || 0;
  const scrollRight =
    (currentRef?.scrollWidth || 0) -
    scrollLeft -
    (currentRef?.clientWidth || 0);

  return { scrollLeft: Boolean(scrollLeft), scrollRight: Boolean(scrollRight) };
};

const getScrollOptions = (multiplier: 1 | -1) => ({
  behavior: 'smooth' as const,
  top: 0,
  left: 228 * multiplier,
});

const multipliers = {
  left: -1,
  right: 1,
} as const;

export const useDashboardScroll = (
  loading = false,
  eventName = 'User Carousels Dashboards'
) => {
  const [grid, setGrid] = useState<Grid>(null);
  const [right, setRight] = useState(false);
  const [left, setLeft] = useState(false);

  useEffect(() => {
    const { scrollLeft, scrollRight } = scrollCallback(
      grid?._scrollingContainer
    );

    setLeft(scrollLeft);
    setRight(scrollRight);
  }, [grid, loading]);

  const onClickScroll = useCallback(
    (side: 'left' | 'right') => () => {
      window.analytics?.track(eventName);

      const currentRef = grid?._scrollingContainer;
      const multiplier = multipliers[side];

      currentRef.scrollBy(getScrollOptions(multiplier));

      setTimeout(() => {
        const { scrollLeft, scrollRight } = scrollCallback(currentRef);
        setLeft(scrollLeft);
        setRight(scrollRight);
      }, 500);
    },
    [grid]
  );

  const listRef = useCallback((node: Grid) => {
    setGrid(node);
  }, []);

  return { listRef, scroll: { right, left }, onClickScroll };
};
