import * as types from './types';

const initialState = {
  coreProspectSelected: true,
};

function homepage(state = initialState, action) {
  switch (action.type) {
    case types.SET_CORE_PROSPECT_SELECTED:
      return {
        coreProspectSelected: action.selected,
      };

    default:
      return state;
  }
}

export default homepage;
