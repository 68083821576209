import { combineReducers } from 'redux';
import filters from './filters/reducers';
import filterSelection from './filter-selection/reducers';
import filterEditing from './filter-editing/reducers';
import results from './results/reducers';
import filtersConfigData from './filters-config-data/reducers';

export default combineReducers({
  filtersConfigData,
  filters,
  filterSelection,
  filterEditing,
  results,
});
