export default (theme) => ({
  root: {
    height: '100vh',
    background: '#941534',
  },
  content: {
    paddingTop: 75,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    position: 'relative',
    overflow: 'hidden',
    zIndex: 2,
    '& h1': {
      fontSize: 120,
      color: '#fff',
      marginTop: 30,
      fontFamily: 'Montserrat, sans-serif;',
      fontWeight: 600,
    },
    '& h4': {
      fontSize: 24,
      color: '#fff',
      fontFamily: 'Montserrat, sans-serif;',
      fontWeight: 400,
      textAlign: 'center',
      padding: '0 20px',
    },
    '& $button': {
      background: '#ff3c6b',
      color: '#fff',
      '&:hover': {
        background: '#ff1c53',
      },
      textTransform: 'none',
      borderRadius: 3,
      paddingLeft: 40,
      paddingRight: 40,
      fontSize: 16,
      marginTop: 25,
    },
  },
  wave: {
    height: '70%',
    background: 'url("/assets/404-wave-background.svg")',
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    position: 'absolute',
    bottom: 0,
    left: 0,
    right: 0,
    top: '30%',
  },
  logo: {
    width: 120,
  },
  footer: {
    position: 'absolute',
    left: 0,
    right: 0,
    bottom: 0,
    color: '#ffc2c2',
    textAlign: 'center',
    padding: 15,
    fontSize: 12,
  },
  artWork: {
    [theme.breakpoints.down(600)]: {
      width: '110%',
      marginTop: 50,
    },
    [theme.breakpoints.up(600)]: {
      width: '110%',
      marginTop: 120,
    },
    [theme.breakpoints.up(800)]: {
      width: '70%',
      marginTop: 0,
    },
    [theme.breakpoints.up(1000)]: {
      width: '60%',
      marginTop: -20,
    },
  },
});
