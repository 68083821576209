import * as types from './types';
const initialState = {
  popoverType: null,
  popoverProps: {},
  anchorEl: null,
};

function modals(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_POPOVER: {
      const { popoverType, anchorEl, popoverProps } = action;

      return {
        popoverType,
        anchorEl,
        popoverProps,
      };
    }
    case types.HIDE_POPOVER:
      return initialState;
    default:
      return state;
  }
}

export default modals;
