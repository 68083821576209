import { makeStyles, Typography } from '@material-ui/core';
import React from 'react';
import { useDispatch } from 'react-redux';
import FavoriteCard from './FavoriteCard';
import { push } from 'connected-react-router';
import SearchFilterSummary from 'src/components/SearchFilterSummary';

/**
 * STYLES
 */

const useStyles = makeStyles(() => ({
  container: {
    display: 'grid',
    gap: 10,
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    color: '#212121',
  },
}));

interface SavedSearchesProps {
  views: {
    view: {
      id: number;
      name: string;
      filters: number;
    };
  }[];
}

const SavedSearches: React.FC<SavedSearchesProps> = ({ views }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  if (!views.length) {
    return null;
  }
  return (
    <div className={classes.container}>
      <Typography className={classes.title}>Saved Searches</Typography>
      {views.map((view) => (
        <FavoriteCard
          key={`search-${view.view.id}`}
          title={view.view.name}
          subtitle={<SearchFilterSummary view={view} />}
          onClick={() => {
            window.analytics?.track('Saved Search Opened - From Home Page');
            dispatch(push(`properties/${view.view.id}`));
          }}
        />
      ))}
    </div>
  );
};

SavedSearches.displayName = 'SavedSearches';

export default SavedSearches;
