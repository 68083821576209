import NumberFormat from 'react-number-format';

import HierarchicalFilter from 'src/components/filters/HierarchicalSelection';

import moment from 'moment';

const createNumberFormatProps = (maskProps, formatProps, value) => {
  return Object.assign(
    {},
    { ...maskProps },
    { ...formatProps },
    { displayType: 'text' },
    { value }
  );
};

const getFormattedNumber = (props) => {
  const numberFormat = new NumberFormat(props);
  return numberFormat.state.value;
};

export const getEarlierDateByNumberOfDays = (days) =>
  moment().subtract(days, 'day').format('MMM DD, YYYY');

export const convertToHasuraOperator = {
  during: '_gte',
  before: '_lte',
};

export const convertToQueryOperator = {
  during: 'greater_than_or_equals',
  before: 'less_than_or_equals',
};

export const daysToLabel = {
  1: 'Last Day',
  7: 'Last Week',
  30: 'Last Month',
  90: 'Last Quarter',
};

const serializeRelativeDate = (val, operator) => {
  const convertedOperator = convertToHasuraOperator[operator]
    ? convertToHasuraOperator[operator]
    : operator;
  const formattedDate = getEarlierDateByNumberOfDays(Number(val));

  return { [convertedOperator]: formattedDate };
};

const wrapper = (fn, operator) => {
  return (val) => {
    return fn(val, operator);
  };
};

function getDisplayValue(value, options = []) {
  if (Array.isArray(value)) {
    return value
      .map((value) => {
        const newValue = options.find((option) => option.value === value);

        if (newValue) {
          return newValue.label;
        }

        return value;
      })
      .join(', ');
  }

  return (
    (options.find((option) => option.value === value) || {}).label || value
  );
}

function getRangeDisplayValue(
  val = {},
  options,
  formatProps,
  maskProps,
  field
) {
  if (!val.min && !val.max) {
    return;
  }

  const minProps = createNumberFormatProps(maskProps, formatProps, val.min);
  const maxProps = createNumberFormatProps(maskProps, formatProps, val.max);

  const min =
    field.mask === 'date' || field.mask === null
      ? val.min
      : getFormattedNumber(minProps);
  const max =
    field.mask === 'date' || field.mask === null
      ? val.max
      : getFormattedNumber(maxProps);

  if (val.min && val.max) {
    return `From ${min} to ${max}`;
  }

  if (val.min) {
    return `From ${min}`;
  }

  if (val.max) {
    return `To ${max}`;
  }
}

export const operators = [
  {
    name: 'is_null',
    label: 'Is null',
    toString: getDisplayValue,
    getValue: (val) => (val === 'yes' ? true : false),
    serialize: (val) => ({ _is_null: val }),
    types: ['String', 'Number', 'Boolean', 'Date'],
  },
  {
    name: 'equals',
    label: 'Equals',
    toString: getDisplayValue,
    getValue: (val, fieldType) => {
      if (fieldType === 'Boolean') {
        return val === 'yes' ? true : false;
      } else {
        return val;
      }
    },
    serialize: (val) => ({ _eq: val }),
    types: ['String', 'Number', 'Boolean', 'Date'],
  },
  {
    name: 'not_equals',
    label: 'Not equals',
    toString: getDisplayValue,
    getValue: (val) => val,
    serialize: (val) => ({ _neq: val }),
    types: ['String', 'Number', 'Boolean'],
  },
  {
    name: 'range',
    label: 'Range',
    toString: getRangeDisplayValue,
    getValue: (val) => val,
    serialize: (val = {}) => {
      if (!val.min && !val.max) {
        return;
      }

      const json = {};

      if (val.min) {
        json._gte = val.min;
      }

      if (val.max) {
        json._lte = val.max;
      }

      return json;
    },
    types: ['Number', 'Date'],
  },
  {
    name: 'greater_than',
    toString: getDisplayValue,
    label: 'Greater than',
    getValue: (val) => val,
    serialize: (val) => ({ _gt: val }),
    types: ['Number', 'Date'],
  },
  {
    name: 'greater_than_or_equals',
    label: 'Greater than or equal',
    toString: getDisplayValue,
    getValue: (val) => val,
    serialize: (val) => ({ _gte: val }),
    types: ['Number', 'Date'],
  },
  {
    name: 'less_than',
    label: 'Less than',
    toString: getDisplayValue,
    getValue: (val) => val,
    serialize: (val) => ({ _lt: val }),
    types: ['Number', 'Date'],
  },
  {
    name: 'less_than_or_equals',
    label: 'Less than or equal',
    toString: getDisplayValue,
    getValue: (val) => val,
    serialize: (val) => ({ _lte: val }),
    types: ['Number', 'Date'],
  },
  {
    name: 'part_of_a_list',
    label: 'Part of a list',
    toString: getDisplayValue,
    getValue: (val) => val,
    serialize: (val, searchParameter) => {
      const filter_type = searchParameter?.attributes?.filter_type;

      if (filter_type === 'HierarchicalFilter') {
        return HierarchicalFilter.serializer.getQuery({}, val);
      }

      return {
        _in: val,
      };
    },
    types: [],
  },
  {
    name: 'not_part_of_a_list',
    label: 'Not part of list',
    toString: getDisplayValue,
    getValue: (val) => val,
    serialize: (val) => ({ _nin: val }),
    types: [],
  },
  {
    name: 'start_with',
    label: 'Start with',
    toString: getDisplayValue,
    getValue: (val) => `${val}%`,
    serialize: (val) => ({ _like: val }),
    types: ['String'],
  },
  {
    name: 'end_with',
    label: 'End with',
    toString: getDisplayValue,
    getValue: (val) => `%${val}`,
    serialize: (val) => ({ _like: val }),
    types: ['String'],
  },
  {
    name: 'match',
    label: 'Match',
    toString: getDisplayValue,
    getValue: (val) =>
      `%${(val || '').replace(/%/g, '').replace(/ +/g, '%').toUpperCase()}%`,
    serialize: (val) => ({ _like: val }),
    types: ['String'],
  },
  {
    name: 'does_not_match',
    label: 'Does not match',
    toString: getDisplayValue,
    getValue: (val) => `%${val}%`,
    serialize: (val) => ({ _nlike: val }),
    types: ['String'],
  },
  {
    name: 'during',
    label: 'During',
    toString: (val) => daysToLabel[Number(val)],
    getValue: (val) => val,
    serialize: wrapper(serializeRelativeDate, 'during'),
    types: ['Date'],
  },
  {
    name: 'before',
    label: 'Before',
    toString: (val) => daysToLabel[Number(val)],
    getValue: (val) => val,
    serialize: wrapper(serializeRelativeDate, 'before'),
    types: ['Date'],
  },
];

const lookupOperators = [
  'equals',
  'not_equals',
  'part_of_a_list',
  'not_part_of_a_list',
];

export function getOperatorByName(name) {
  return operators.find((operator) => operator.name === name);
}

export function getOperatorsByFieldType(fieldType, areOptionsAvailable) {
  if (areOptionsAvailable) {
    return operators.filter((operator) => {
      return lookupOperators.includes(operator.name);
    });
  }

  return operators.filter((operator) => {
    return operator.types.includes(fieldType);
  });
}
