import * as types from './types';
import * as filterTypes from '../filters/types';
import * as filterSelectionTypes from '../filter-selection/types';
import constants from 'src/const';
// import defaultTableResults from './defaultTableResults';
const { LOADING, LOADED, FAILED } = constants;

const initialState = {
  fetchStatus: {},
  value: [],
  count: null,
  offset: 0,
  previewOffset: 0,
  selectedMapEntity: null,
  colDefinitions: [],
};

export default function results(state = initialState, action) {
  switch (action.type) {
    case filterTypes.RESET_ALL_FILTERS:
    case filterSelectionTypes.TOGGLE_SEARCH_ENTITY:
      return initialState;

    case types.CHANGE_COUNT: {
      return {
        ...state,
        count: action.count,
      };
    }

    case types.GET_COL_DEFINITIONS: {
      return {
        ...state,
        colDefinitions: action.data.data,
      };
    }

    case filterTypes.CHANGE_FILTER_FIELD: {
      return {
        ...state,
        offset: 0,
      };
    }

    case types.SELECT_MAP_RESULT:
      return {
        ...state,
        selectedMapEntity: action.entity,
        previewOffset: action.resetOffset ? 0 : state.previewOffset,
      };

    case types.CHANGE_OFFSET:
      return {
        ...state,
        offset: action.offset,
      };

    case types.CHANGE_PREVIEW_OFFSET:
      return {
        ...state,
        previewOffset: action.offset,
      };

    case types.GET_ENTITIES_COUNT_SUCCESS:
      return {
        ...state,
        count: action.data.entity.aggregate.count,
      };

    case types.GET_ENTITIES_REQUEST:
      return {
        ...state,
        fetchStatus: {
          value: LOADING,
        },
      };

    case types.GET_ENTITIES_SUCCESS:
      return {
        ...state,
        fetchStatus: {
          value: LOADED,
        },
        value: action.data.entity,
      };

    case types.GET_ENTITIES_FAILED:
      return {
        ...state,
        fetchStatus: {
          value: FAILED,
        },
      };

    default: {
      return state;
    }
  }
}
