import React from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import ApiSearchInput from 'src/products/api-doc/components/ApiSearchInput/index';
import { usePermission } from 'src/hooks';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import Description from '@material-ui/icons/Description';
import Search from '@material-ui/icons/Search';
import { grey } from '@material-ui/core/colors';
import FeaturedArticles from './FeaturedArticles';

/**
 * STYLES
 */
const useStyles = makeStyles((theme) => ({
  title: {
    display: 'flex',
    placeItems: 'center',
  },
  search: {
    position: 'relative',
    margin: '17px 17px 0px 0px',
    '& .react-autosuggest__input': {
      borderRadius: 10,
      boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
      backgroundColor: '#fff',
      padding: '20px',
      fontSize: 14,
      paddingLeft: 50,
    },
    '& .react-autosuggest__suggestions-container--open': {
      borderRadius: 10,
      position: 'absolute',
    },
  },
  searchIcon: {
    position: 'absolute',
    top: 'calc(50% - 10px)',
    left: '20px',
    fontSize: 20,
    color: grey[600],
  },
  searchContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  viewApiDocButton: {
    marginTop: 17,
    borderRadius: 10,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '15px 35px',
    color: theme.palette.primary.main,
  },
}));

const DeveloperPortal: React.FC = () => {
  const isDev = usePermission('CoreAPI');
  const classes = useStyles();

  const dispatch = useDispatch();

  if (!isDev) {
    return null;
  }
  return (
    <div>
      <span className={classes.title}>
        <Typography style={{ fontSize: 32 }} variant='h2'>
          Search API Documentation
        </Typography>
      </span>
      <div className={classes.searchContainer}>
        <div className={classes.search}>
          <Search className={classes.searchIcon} />
          <ApiSearchInput
            onChange={() =>
              window.analytics?.track('User Searches API Documentation')
            }
            placeholder='Search objects, keys, functions'
          />
        </div>
        <Button
          className={classes.viewApiDocButton}
          onClick={() => {
            window.analytics?.track('User Selects View API Docs');
            dispatch(push('core-api'));
          }}
        >
          <Description /> View API Docs
        </Button>
      </div>
      <FeaturedArticles />
    </div>
  );
};

DeveloperPortal.displayName = 'DeveloperPortal';

export default DeveloperPortal;
