import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getOrganizationAdmin } from 'src/products/shell/redux/actions/common';

const AccessDeniedHOC = (Component) => {
  class HOC extends React.Component {
    static propTypes = {
      getOrganizationAdmin: PropTypes.func,
    };

    state = {
      admin: {},
    };

    componentDidMount() {
      this.props
        .getOrganizationAdmin()
        .then((admin) => this.setState({ admin }))
        .catch(console.log);
    }

    render() {
      return <Component admin={this.state.admin} />;
    }
  }

  return connect(() => ({}), { getOrganizationAdmin })(HOC);
};

export default AccessDeniedHOC;
