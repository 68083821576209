import React, { useEffect } from 'react';
import { Button, makeStyles, Typography } from '@material-ui/core';
import { Autocomplete } from 'src/products/core-prospect/search-pages/components/Sidebar/components/Autocomplete';
import { usePermission } from 'src/hooks';
import FavoritesLists from './FavoritesLists';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useDisableNextCoreExploreReset } from 'src/products/core-prospect/search-pages/hooks/useResetCoreExplore';
import { resetCoreExplore } from 'src/products/core-prospect/redux/getActions';

/**
 * STYLES
 */
const useStyles = makeStyles((theme) => ({
  searchContainer: {
    display: 'grid',
    gridTemplateColumns: '1fr auto',
  },
  fitlersButton: {
    marginTop: 17,
    borderRadius: 10,
    boxShadow: '0 2px 5px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    padding: '15px 35px',
    color: theme.palette.primary.main,
  },
}));

const ExploreFoundationData: React.FC = () => {
  const isAnalyst = usePermission('CoreProspect');
  const classes = useStyles();

  const dispatch = useDispatch();

  const { disableNextReset } = useDisableNextCoreExploreReset();

  useEffect(() => {
    dispatch(resetCoreExplore);
  }, []);

  if (!isAnalyst) {
    return null;
  }

  return (
    <div>
      <Typography style={{ fontSize: 32 }} variant='h2'>
        Search Properties
      </Typography>
      <div className={classes.searchContainer}>
        <Autocomplete
          where='HomePage'
          onBeforeSuggestionSelected={disableNextReset}
        />
        <Button
          className={classes.fitlersButton}
          onClick={() => {
            window.analytics?.track('Home Page - Launch Search Clicked');
            disableNextReset();
            dispatch(push('properties'));
          }}
        >
          <span className='material-icons'>filter_alt</span> Launch Search
        </Button>
      </div>
      <FavoritesLists />
    </div>
  );
};

ExploreFoundationData.displayName = 'ExploreFoundationData';

export default ExploreFoundationData;
