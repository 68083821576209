export const namespace = 'coreQuery';
const ns = (name) => `${namespace}/${name}`;

export const GET_LOOKUPS_BY_TABLE_NAME_REQUEST = ns(
  'GET_LOOKUPS_BY_TABLE_NAME_REQUEST'
);
export const GET_LOOKUPS_BY_TABLE_NAME_SUCCESS = ns(
  'GET_LOOKUPS_BY_TABLE_NAME_SUCCESS'
);
export const GET_LOOKUPS_BY_TABLE_NAME_FAILED = ns(
  'GET_LOOKUPS_BY_TABLE_NAME_FAILED'
);

export const LOOKUPS_REQUEST = ns('LOOKUPS_REQUEST');
export const LOOKUPS_SUCCESS = ns('LOOKUPS_SUCCESS');
export const LOOKUPS_FAILED = ns('LOOKUPS_FAILED');
