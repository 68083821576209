import React from 'react';
import {
  createDataFetchingConfig,
  DataFetchingError,
  ErrorBoundaryComponent,
  NotFoundError,
} from '@cherre-frontend/data-fetching';
import { SuspenseWrapper, ResumeWrapper } from '@cherre-frontend/ui';
import axios from 'axios';
import { dspEnvironmentFactory } from './DSPRelayEnvironment';
import { Redirect } from 'react-router';
import { DomEnvironment } from './DomRelayEnvironment';

export const DefaultErrorBoundary: ErrorBoundaryComponent = ({ error }) => {
  console.log({ error });
  if (error instanceof NotFoundError) {
    return <Redirect to='/notfound' />;
  }
  if (error instanceof DataFetchingError) {
    if (error.originalError instanceof NotFoundError) {
      return <Redirect to='/notfound' />;
    }
  }
  return null;
};

export const dataFetchingConfig = createDataFetchingConfig({
  axiosDefaultEnvironment: () => Promise.resolve(axios),
  relayDefaultEnvironment: dspEnvironmentFactory(),
  relayEnvironments: {
    preparer: dspEnvironmentFactory('preparer'),
    reviewer: dspEnvironmentFactory('reviewer'),
    view_only: dspEnvironmentFactory('view_only'),
    view_admin: dspEnvironmentFactory('dsp-frontend-admin'),
    cherre_admin: dspEnvironmentFactory('dsp-cherre-admin'),
    dom: () => Promise.resolve(DomEnvironment),
  },
  defaultErrorBehavior: 'fallback',
  defaultErrorBoundary: DefaultErrorBoundary,
  suspenseWrapper: SuspenseWrapper,
  resumeWrapper: ResumeWrapper,
});
