import { getOperatorByName } from './operators';

const isEmpty = (val) => {
  if ([null, undefined, ''].includes(val)) {
    return true;
  }

  if (Array.isArray(val)) {
    return val.length === 0;
  }

  if (typeof val === 'object') {
    return Object.keys(val).length === 0;
  }

  return false;
};

const getFilterWhereValue = (searchParameter) => {
  const value =
    searchParameter && searchParameter.attributes
      ? searchParameter.attributes.value
      : null;
  const operator =
    searchParameter && searchParameter.attributes
      ? searchParameter.attributes.operator
      : 'equals';

  const operatorByName = getOperatorByName(operator);

  if (!operatorByName) {
    return value;
  }

  if (!isEmpty(value)) {
    return operatorByName.serialize(
      operatorByName.getValue(value),
      searchParameter
    );
  }
};

export { getFilterWhereValue };
