import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { GraphQLClient } from './GraphQLClient';

// Create a network layer from the fetch function

const environments = new Map<string, Environment>();

const getEnvironment = (key = 'default') => {
  let environment = environments.get(key);
  if (!environment) {
    const network = Network.create(
      GraphQLClient.fetchOrSubscribe(key),
      GraphQLClient.fetchOrSubscribe(key)
    );
    environment = new Environment({
      network,
      store: new Store(new RecordSource()),
    });
    environments.set(key, environment);
  }
  return environment;
};

export const dspEnvironmentFactory =
  (key = 'default') =>
  async () => {
    await GraphQLClient.getClient(key);
    return getEnvironment(key);
  };
