import * as types from './types';
import constants from 'src/const';

const { LOADING, LOADED } = constants;

export const getLookupsByTableName = () => {
  return (dispatch, getState) => {
    const {
      common: {
        lookups: {
          fetchStatusByTableName: { value: fetchStatusByTableName },
        },
      },
    } = getState();

    if (fetchStatusByTableName) {
      return;
    }

    return dispatch({
      url: '/lookups/by-table-name',
      types: [
        types.GET_LOOKUPS_BY_TABLE_NAME_REQUEST,
        types.GET_LOOKUPS_BY_TABLE_NAME_SUCCESS,
        types.GET_LOOKUPS_BY_TABLE_NAME_FAILED,
      ],
    });
  };
};

export const requestLookups = () => {
  return (dispatch, getState) => {
    const {
      common: {
        lookups: {
          fetchStatus: { value: fetchStatus },
        },
      },
    } = getState();

    if ([LOADED, LOADING].includes(fetchStatus)) {
      return;
    }

    return dispatch({
      url: '/lookups',
      types: [
        types.LOOKUPS_REQUEST,
        types.LOOKUPS_SUCCESS,
        types.LOOKUPS_FAILED,
      ],
    });
  };
};
