import * as types from './types';

export const showModal = (modalType, modalProps, onClose) => {
  return {
    type: types.SHOW_MODAL,
    modalType,
    modalProps,
    onClose,
  };
};

export const hideModal = () => {
  return {
    type: types.HIDE_MODAL,
  };
};
