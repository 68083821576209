import React from 'react';
import PropTypes from 'prop-types';
import Paper from '@material-ui/core/Paper';
import withStyles from '@material-ui/core/styles/withStyles';
import { Typography } from '@material-ui/core';

const styles = {
  root: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: 100,
  },
  paper: {
    padding: 20,
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  title: {
    fontSize: 22,
    marginBottom: 15,
    fontWeight: 500,
    color: '#000',
  },
  icon: {
    width: 150,
    height: 150,
    fontSize: 185,
    opacity: 0.3,
    alignSelf: 'flex-end',
    marginTop: 50,
  },
};

function AccessDenied({ classes, children, title }) {
  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <Typography component='div' style={{ marginBottom: 25 }} variant={'h5'}>
          {title || 'No permissions'}
        </Typography>
        {children}
      </Paper>
    </div>
  );
}

AccessDenied.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};

export default withStyles(styles)(AccessDenied);
