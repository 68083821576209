import * as UserActionTypes from '../actions/user';
import { combineReducers, Reducer } from 'redux';
import * as HOME_PAGE_TYPES from 'src/products/shell/pages/Home/redux/types';
import { FetchStatus } from 'src/products/core-prospect/redux/query/reducers';

export type Permission =
  | 'NYCNavigator'
  | 'CherreAdmin'
  | 'CoreAPI'
  | 'AnalyticsAPI'
  | 'CoreProspect'
  | 'CoreBI'
  | 'PowerBI'
  | 'PowerBIAdmin'
  | 'UserManagement'
  | 'CoreSchema'
  | 'DataSourceManagement'
  | 'CoreExploreSearch'
  | 'CherreDev'
  | 'CherreAdminReadOnly'
  | 'EmulateUser'
  | 'ApiKeyManagement'
  | 'DSPAdmin'
  | 'DSPRegular'
  | 'DSPUserManagement'
  | 'DSPEmulateUser'
  | 'DataConnections'
  | 'Connectors'
  | 'ConnectorsAdmin'
  | 'IDAdmin'
  | 'RoleAdmin'
  | 'ProductAdmin'
  | 'EmulateNonIDAdmin';

export type FeatureFlag =
  | 'BoundarySearch'
  | 'isHomePageCherreGraphHidden'
  | 'marketAnalytics'
  | 'TestPythonClient'
  | 'mailingAddressLink'
  | 'CoreExploreTabs'
  | 'marketFundamentalsFiltersEnabled'
  | 'cp_OwnerRelatedOwners'
  | 'censusFiltersEnabled'
  | 'shortTermRentalsMarket'
  | 'rcaLotsPage'
  | 'isEqtDealsIntegrationAvailable'
  | 'drawToSearch'
  | 'CoreExploreTabs_owners'
  | 'CoreExplore_owners_filters'
  | 'CoreExplore_LotCard_preview'
  | 'CoreExplore_MapLayers_heatmaps'
  | 'CoreExplore_OwnerDetails'
  | 'cp_ListingDetails'
  | 'app_notifications'
  | 'treppDataLotsPage'
  | 'CoreExploreTabs_zipcodes'
  | 'cp_pdfExport'
  | 'tax-record-preview-drawer'
  | 'lot_feedback_dialog'
  | 'appBarPropertyListsEnabled'
  | 'safegraphMarketAnalytics'
  | 'cp_OwnerProperties'
  | 'app_Intercom'
  | 'cp_PropertyLists'
  | 'list_api_keys'
  | 'cherreFoundationalDataMarketAnalytics'
  | 'marketAnalyticsTopFactorsDrivingSales'
  | 'app_UploadFile'
  | 'DSPVersion1'
  | 'DSPValidations'
  | 'DSPJournalEntry'
  | 'DSPCashReceipts'
  | 'DSPPropertyOperatingBudget'
  | 'DSPCashDisbursements'
  | 'DSPTenantCharges'
  | 'DSPAccountsPayableInvoices'
  | 'DSPAgedDelinquencies'
  | 'DSPSecurityDeposits'
  | 'DSPTenancyData'
  | 'DSPOccupancy'
  | 'DSPMarketingActivity'
  | 'DSPRetailSales'
  | 'DSPFootfall'
  | 'DSPBankAccountsList'
  | 'DSPBankAccountAccessList'
  | 'CoreBIDashboardControlPanel'
  | 'DSPFiscalYearCheckbox'
  | 'DSPMonitorSubmissions'
  | 'DSPAddEditProvider'
  | 'DspAdminUploadMapping'
  | 'DSPReminders'
  | 'DspAdminUploadProperty'
  | 'DSPAddDeleteMasterFiles'
  | 'DSPNewIngestion'
  | 'DSPExcludedProperties'
  | 'PowerBIDebug'
  | 'DSPNewIngestionAdmin'
  | 'DSPIngestionFormatsEdit'
  | 'PostHogEnabled'
  | 'TargetTableCreation'
  | 'ConnectorsCreateConnector'
  | 'ConnectorsDataValidationV2'
  | 'DSPBulkApproveSettings'
  | 'ModelLibraryEnabled'
  | 'DSPMultiProviderPropertySubmissions'
  | 'DSPSubmissionTestingMode';

export enum DashboardLandingPageSource {
  LOOKER = 'looker',
  POWERBI = 'powerbi',
}

export type User = {
  permissions: Permission[];
  organizationId: number;
  organizationSlug: string;
  rowExportLimit: number;
  fileExportLimit: number;
  defaultMapToSatelliteView: boolean;
  safegraphEnabled: boolean;
  compstakEnabled: boolean;
  rcaEnabled: boolean;
  treppEnabled: boolean;
  reisEnabled: boolean;
  coreExploreHasuraRole: string;
  organizationName: string;
  organizationAuthzVersion: {
    id: number;
    name: string;
    description: string | null;
  } | null;
  domOwner: string;
  isEmulatedSession: boolean;
  emulatedBy: string;
  role: string;
  email: string;
  id: number;
  intercomHashedUserId: string | undefined;
  createdAt: string;
  firstName: string;
  lastName: string;
  themeName: string | null;
  organizationProps: {
    deals_enabled: boolean;
    rca_enabled: boolean;
    trepp_enabled: boolean;
    compstak_enabled: boolean;
    safegraph_enabled: boolean;
    organization_looker_email: string;
    plan_type: string;
    plan_expires_at: string;
    looker_theme?: string;
    looker_creating_enabled: boolean;
    dashboard_landing_page?: string;
    dashboard_property_link?: string;
    dashboard_landing_page_source?: DashboardLandingPageSource;
  };
  hasLoggedIn: boolean;
  globalSettings: any;
  featureFlags: Record<FeatureFlag, boolean>;
};

type ProfileState = {
  value: Partial<User>;
  fetchStatus: FetchStatus;
  orgMembersCount: number | null;
  orgMembersCountFetchStatus: FetchStatus;
};

type ProfileFormState = {
  value: Partial<User>;
  fetchStatus: FetchStatus;
  errors: any;
};

const profileInitialState: ProfileState = {
  value: { permissions: [] },
  fetchStatus: {},
  orgMembersCount: null,
  orgMembersCountFetchStatus: {},
};

const profileFormInitialState: ProfileFormState = {
  value: {},
  fetchStatus: {},
  errors: { $isPristine: true },
};

const profileForm: Reducer<ProfileFormState> = (
  state = profileFormInitialState,
  action
) => {
  switch (action.type) {
    case UserActionTypes.ME_SUCCESS:
      return {
        ...state,
        value: action.data,
      };

    case UserActionTypes.CHANGE_PROFILE: {
      return {
        ...state,
        value: {
          ...state.value,
          ...action.data,
        },
      };
    }

    case UserActionTypes.PROFILE_UPDATE_REQUEST:
      return {
        ...state,
        fetchStatus: {
          value: 'LOADING',
        },
      };

    case UserActionTypes.PROFILE_UPDATE_SUCCESS:
      return {
        ...state,
        fetchStatus: {
          value: 'LOADED',
          message: 'Your profile was successfully updated',
        },
      };

    case UserActionTypes.PROFILE_UPDATE_FAILED: {
      const message = Array.isArray(action.error)
        ? action.error[0].message
        : action.error.message;

      return {
        ...state,
        fetchStatus: {
          value: 'FAILED',
          message,
        },
      };
    }

    case UserActionTypes.PROFILE_FORM_CHANGED:
      return {
        ...state,
        value: {
          ...state.value,
          ...action.updatedFields,
        },
      };

    case UserActionTypes.PROFILE_FORM_CHANGED_ERROR:
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.errorFields,
          $isPristine: false,
        },
      };

    default:
      return state;
  }
};

const profile: Reducer<ProfileState> = (
  state = profileInitialState,
  action
) => {
  switch (action.type) {
    case UserActionTypes.CHANGE_PROFILE: {
      return {
        ...state,
        value: {
          ...state.value,
          ...action.data,
        },
      };
    }
    case UserActionTypes.ORG_MEMBERS_COUNT_REQUEST: {
      return {
        ...state,
        orgMembersCountFetchStatus: { value: 'LOADING' },
      };
    }

    case UserActionTypes.ORG_MEMBERS_COUNT_SUCCESS: {
      return {
        ...state,
        orgMembersCount: action.data,
        orgMembersCountFetchStatus: { value: 'LOADED' },
      };
    }

    case UserActionTypes.ORG_MEMBERS_COUNT_FAILED: {
      return {
        ...state,
        orgMembersCountFetchStatus: { value: 'FAILED' },
      };
    }

    case UserActionTypes.ME_SUCCESS:
      return {
        ...profileInitialState,
        value: action.data,
        fetchStatus: {
          value: 'LOADED',
        },
      };

    case HOME_PAGE_TYPES.UPDATE_USER_FIRST_TIME_LOGIN_SUCCESS:
      return {
        ...state,
        value: {
          ...state.value,
          hasLoggedIn: true,
        },
      };

    case UserActionTypes.PROFILE_UPDATE_SUCCESS: {
      return {
        ...state,
        value: action.data,
      };
    }

    case UserActionTypes.ME_REQUEST:
      return {
        ...state,
        value: {
          ...state.value,
        },
        fetchStatus: {
          value: 'LOADING',
        },
      };

    case UserActionTypes.ME_FAILED:
      return {
        ...state,
        fetchStatus: {
          value: 'FAILED',
          message: action.error,
        },
      };

    default:
      return state;
  }
};

export default combineReducers({ profile, profileForm });
