import * as types from './types';

const initialState = {
  modalType: '',
  props: {},
  modalProps: {},
  onClose: () => {},
};

function modals(state = initialState, action) {
  switch (action.type) {
    case types.SHOW_MODAL:
      return {
        modalType: action.modalType,
        modalProps: action.modalProps,
        onClose: action.onClose,
      };

    case types.HIDE_MODAL:
      return initialState;

    default:
      return state;
  }
}

export default modals;
