import React from 'react';
import pluralize from 'pluralize';
import Typography from '@material-ui/core/Typography';
import { upperFirst } from 'lodash';
import { Link as MUILink, styled } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getOrgMembersCount } from 'src/products/shell/redux/actions/user';
import { inviteMore } from '../redux/actions';

/**
 * STYLES
 */

const Header = styled('div')({
  position: 'absolute',
  right: 22,
  top: 12,
});

/**
 * UTILITIES
 */

type Child = React.ReactElement<any, string | React.JSXElementConstructor<any>>;

const middot = <span> &middot; </span>;

const childrenReducer = (acc: Child[], cur: Child, index: number): Child[] => {
  const _cur = React.cloneElement(cur, { key: `element-${index}` });
  if (index === 0) {
    return [_cur];
  }
  const _middot = React.cloneElement(middot, { key: `middot-${index - 1}` });
  return [...acc, _middot, _cur];
};

const addMiddot = (children: any[]) =>
  children.filter(Boolean).reduce(childrenReducer, []);

/**
 * SELECTORS
 */

const profileSelector = (state: any) => {
  return state?.user?.profile;
};

const teamNameSelector = (state: any) => {
  const profile = profileSelector(state);
  const teamName = profile?.value?.organizationName?.replace(' Team', '');
  return upperFirst(`${teamName} Team`);
};

const orgMembersCountSelector = (state: any) => {
  const profile = profileSelector(state);
  return Number(profile?.orgMembersCount || 0);
};

const planTypeSelector = (state: any) => {
  const profile = profileSelector(state);
  return profile?.value?.organizationProps?.plan_type;
};

const organizationIdSelector = (state: any) => {
  const profile = profileSelector(state);
  return profile?.value?.organizationId;
};

/**
 * SECONDARY COMPONENTS
 */

const HeaderContainer: React.FC = ({ children }) => {
  return <Header>{addMiddot(React.Children.toArray(children))}</Header>;
};

/**
 * MAIN COMPONENT
 */

const HomePageHeader: React.FC = () => {
  const teamName = useSelector(teamNameSelector);
  const orgMembersCount = useSelector(orgMembersCountSelector);
  const planType = useSelector(planTypeSelector);
  const organizationId = useSelector(organizationIdSelector);

  const dispatch = useDispatch();

  React.useEffect(() => {
    if (organizationId) {
      dispatch(getOrgMembersCount());
    }
  }, [organizationId]);

  return (
    <HeaderContainer>
      <Typography
        display='inline'
        style={{ color: 'black', fontWeight: 'bold' }}
      >
        {teamName}
      </Typography>
      {planType === 'trial' ? (
        <Typography display='inline' style={{ color: '#757575' }}>
          Trial Account
        </Typography>
      ) : null}
      <Typography display='inline' style={{ color: '#757575' }}>
        {pluralize('user', orgMembersCount, true)}
      </Typography>
      <MUILink
        onClick={() => dispatch(inviteMore())}
        to='/customerAdmin/users'
        component={Link}
      >
        <Typography display='inline'>Invite More</Typography>
      </MUILink>
    </HeaderContainer>
  );
};

HomePageHeader.displayName = 'HomePageHeader';

export default HomePageHeader;
