import { makeStyles, Typography } from '@material-ui/core';
import ArrowForward from '@material-ui/icons/ArrowForward';
import ArrowBack from '@material-ui/icons/ArrowBack';
import React from 'react';
import { RootState, useDispatch, useSelector } from 'react-redux';
import { getDashboards } from 'src/products/core-bi/redux/dashboards-preveiw/actions';
import { DashboardCard } from './DashboardCard';
import { push } from 'connected-react-router';
import { usePermission } from 'src/hooks';
import { useDashboardScroll } from './useDashboardScroll';
import { CreateDashboardCard } from './CreateDashboardCard';
import { Grid, AutoSizer } from 'react-virtualized';
import { NoDashboardsCard } from './NoDashboardsCard';
import { Skeleton } from '@material-ui/lab';

/**
 * STYLES
 */

const useStyles = makeStyles((style) => ({
  container: {
    display: 'grid',
    gridTemplateColumns: '36px auto 1fr auto 36px',
    gridTemplateAreas: `
      "title title . view-all view-all"
      "arrow-left list list list arrow-right"`,
    alignItems: 'center',
    margin: '0px -18px',
  },
  list: {
    gridArea: 'list',
    justifyContent: 'flex-start',
    overflow: 'hidden',
    padding: '20px 5px',
    margin: '0px -18px',
    height: '220px',
  },
  spacer: {
    width: 2,
  },
  arrow: {
    width: 36,
    height: 36,
    backgroundColor: '#fff',
    borderRadius: '50%',
    boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.5)',
    display: 'grid',
    placeItems: 'center',
    zIndex: 10,
  },
  right: {
    gridArea: 'arrow-right',
  },
  left: {
    gridArea: 'arrow-left',
  },
  title: {
    gridArea: 'title',
    fontSize: 32,
    paddingLeft: '18px',
  },
  viewAll: {
    gridArea: 'view-all',
    color: style.palette.primary.main,
    cursor: 'pointer',
    fontSize: '14px',
    display: 'grid',
    alignItems: 'center',
    gridTemplateColumns: 'auto auto',
    paddingRight: '18px',
    gap: '5px',
    '& svg': {
      fontSize: '16px',
    },
  },
  grid: {
    overflow: 'hidden !important',
  },
}));

/**
 * SELECTORS
 */

const orgIdSelector = (state: RootState) =>
  state.user.profileForm.value.organizationId;

const dashboardsSelector = (state: RootState) =>
  state.coreBI.dashboards_preview.dashboards;

const fetchStatusSelector = (state: RootState) =>
  state.coreBI.dashboards_preview.fetchStatusDashboards;

export const isCreatingEnabledSelector = (state: RootState) =>
  state.user?.profile?.value?.organizationProps?.looker_creating_enabled;

/**
 * MAIN COMPONENT
 */

const DashboardsSelector: React.FC = () => {
  const dispatch = useDispatch();

  const orgId = useSelector(orgIdSelector) || 0;
  const dashboards = useSelector(dashboardsSelector);
  const fetchStatusDashboards = useSelector(fetchStatusSelector);

  const isLookerCreatingEnabled = useSelector(isCreatingEnabledSelector);

  const numOfDashs =
    (dashboards.length || 0) + (isLookerCreatingEnabled ? 1 : 0);
  const classes = useStyles(numOfDashs);

  const isAnalyst = usePermission('CoreBI');
  const loading = fetchStatusDashboards !== 'LOADED';

  React.useEffect(() => {
    if (isAnalyst) {
      dispatch(getDashboards(orgId));
    }
  }, [orgId, isAnalyst]);

  const { listRef, scroll, onClickScroll } = useDashboardScroll(loading);

  if (!isAnalyst) {
    return null;
  }

  const dashLen =
    isLookerCreatingEnabled || dashboards.length === 0
      ? dashboards.length + 1
      : dashboards.length;

  const columnCount = loading ? 15 : dashLen;

  return (
    <div className={classes.container}>
      <Typography className={classes.title} variant='h2'>
        My Dashboards
      </Typography>
      <div
        onClick={() => {
          window.analytics?.track('User Selects View All on Dashboards');
          dispatch(push('dashboards'));
        }}
        className={classes.viewAll}
      >
        View all <ArrowForward fontSize='inherit' />
      </div>
      {scroll.left && (
        <div
          onClick={onClickScroll('left')}
          className={classes.arrow + ' ' + classes.left}
          data-testid='arrow-left'
        >
          <ArrowBack />
        </div>
      )}
      {scroll.right && (
        <div
          onClick={onClickScroll('right')}
          className={classes.arrow + ' ' + classes.right}
          data-testid='arrow-right'
        >
          <ArrowForward />
        </div>
      )}
      <div className={classes.list}>
        <AutoSizer disableHeight>
          {({ width }) => (
            <Grid
              className={classes.grid}
              ref={listRef}
              direction='horizontal'
              height={200}
              width={width}
              columnCount={columnCount}
              columnWidth={230}
              rowHeight={190}
              rowCount={1}
              cellRenderer={({ columnIndex, style }) => {
                const correctIndex = isLookerCreatingEnabled
                  ? columnIndex - 1
                  : columnIndex;
                let component = (
                  <Skeleton
                    variant='rect'
                    width={203}
                    height={180}
                    style={{
                      borderRadius: 6,
                    }}
                  />
                );

                if (!loading) {
                  if (columnIndex === 0 && isLookerCreatingEnabled) {
                    component = <CreateDashboardCard />;
                  } else if (columnIndex === 0 && dashboards.length === 0) {
                    component = <NoDashboardsCard />;
                  } else {
                    component = <DashboardCard {...dashboards[correctIndex]} />;
                  }
                }

                return (
                  <div key={`dash-${columnIndex}`} style={style}>
                    {component}
                  </div>
                );
              }}
            />
          )}
        </AutoSizer>
      </div>
    </div>
  );
};

DashboardsSelector.displayName = 'HomePageDashboardsSelector';

export default DashboardsSelector;
