import React from 'react';
import { usePermission } from 'src/hooks';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import {
  styled,
  Typography,
  ReportWindowIcon,
  AdminIcon,
  UploadFileIcon,
  CityIcon,
  SubmissionIcon,
  FileOkIcon,
  DashboardIcon,
} from '@cherre-frontend/ui';
import Card from './Card';
import { useHistory } from 'react-router';
import { isDspRole } from 'src/utils/routing/routeFactory';

/**
 * STYLES
 */
const Title = styled('span')`
  display: flex;
  place-items: center;
`;

const CardContainer = styled('div')`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 12px;
  margin-top: 10px;
`;

const usePreparer = isDspRole('preparer');
const useReviewer = isDspRole('reviewer');
const useViewOnly = isDspRole('view_only');

const DSPSection: React.FC = () => {
  const isAdmin = usePermission('DSPAdmin');
  const isRegular = usePermission('DSPRegular');
  const isPreparer = usePreparer();
  const isReviewer = useReviewer();
  const isViewOnly = useViewOnly();
  const DSPMonitorSubmissions =
    useFeatureFlag('DSPMonitorSubmissions') ?? false;
  const shouldShow = isAdmin || isRegular;
  const { push } = useHistory();
  if (!shouldShow) {
    return null;
  }
  return (
    <div>
      <Title>
        <Typography style={{ fontSize: 32 }} variant='h2'>
          Data Submission Portal
        </Typography>
      </Title>
      {isAdmin ? (
        <CardContainer>
          <Card
            hide={!DSPMonitorSubmissions}
            title='Submission Insights'
            subtitle='Review submissions, track cycle performance, and remediate issues.'
            icon={<ReportWindowIcon />}
            onClick={() => push('/dsp/submissions-insights')}
          />
          <Card
            title='Service Providers'
            subtitle='Manage users, mappings, and entity assignments.'
            icon={<AdminIcon />}
            onClick={() => push('/dsp/providers')}
          />
          <Card
            title='Batch Management'
            subtitle='Create and manage submission batches for service providers.'
            icon={<UploadFileIcon />}
            onClick={() => push('/dsp/batch-management')}
          />
          <Card
            title='Properties'
            subtitle='View all entities and provider assignments in your portfolio.'
            icon={<CityIcon />}
            onClick={() => push('/dsp/properties')}
          />
        </CardContainer>
      ) : null}
      <CardContainer>
        <Card
          hide={!isPreparer}
          title='Prepare Submissions'
          subtitle='Upload, validate, and review data for your submissions.'
          icon={<SubmissionIcon />}
          onClick={() => push('/dsp/preparer/submissions')}
        />
        <Card
          hide={!isReviewer}
          title='Review Submissions'
          subtitle='Review and approve or reject your submissions.'
          icon={<FileOkIcon />}
          onClick={() => push('/dsp/reviewer/submissions')}
        />
        <Card
          hide={!isViewOnly}
          title='View Submissions'
          subtitle='View-only access to monitor your submissions.'
          icon={<DashboardIcon />}
          onClick={() => push('/dsp/view_only/submissions')}
        />
      </CardContainer>
    </div>
  );
};

export default DSPSection;
