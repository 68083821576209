import React from 'react';
import { hot } from 'react-hot-loader/root';
import { Provider as ReduxProvider, useSelector } from 'react-redux';
import { GraphQLClient, ClientContext } from 'graphql-hooks';
import axios from 'axios';
import { buildAxiosFetch } from '@lifeomic/axios-fetch';
import { ThemeProvider as StyledComponentsProvider } from 'styled-components';
import {
  MuiThemeProvider,
  createMuiTheme,
  StylesProvider,
  createGenerateClassName,
} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import muiThemeSettings from 'src/const/theme';
import { ConnectedRouter } from 'connected-react-router';
import memCache from 'graphql-hooks-memcache';
import { SnackbarProvider } from 'notistack';
import { PostHogProvider } from 'posthog-js/react';

import redux from 'src/products/shell/redux/store/configureStore';
import Routes from 'src/products/shell/apps/cherre/Routes';
import { ConditionalProvider } from '../../components/ConditionalProvider';
import { IS_SEGMENT_TRACKER_ENABLED, CHERRE_POSTHOG_KEY } from 'src/const';

import * as Sentry from '@sentry/react';

import { AppContextProvider } from '@cherre-frontend/core';
import { CherreThemeProvider } from '@cherre-frontend/ui';
import { DataFetchingProvider } from '@cherre-frontend/data-fetching';
import { appContextConfig } from './AppContextConfig';
import { dataFetchingConfig } from './DataFetchingConfig';
import { themeV5 } from 'src/const/themev5';
import { useFeatureFlag } from 'src/hooks/useFeatureFlag';
import { createDomEnvironment } from './DomRelayEnvironment';

require('src/products/shell/less/App.less');

axios.interceptors.response.use(null, (error) => {
  if (axios.isAxiosError(error) && error.response?.status === 403) {
    window.location.replace('/accessDenied');
  }
  return Promise.reject(error);
});

const { dsn, tracesSampleRate, replaysOnErrorSampleRate } = window.sentryConfig;
if (dsn) {
  const tracingEnabled = !!tracesSampleRate;
  const replaysEnabled = !!replaysOnErrorSampleRate;

  Sentry.init({
    ...window.sentryConfig,
    integrations: [
      tracingEnabled && Sentry.browserTracingIntegration(),
      tracingEnabled &&
        Sentry.reactRouterV5BrowserTracingIntegration({
          history: redux.history,
        }),

      replaysEnabled && Sentry.replayIntegration(),
    ].filter(Boolean),

    // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
    tracePropagationTargets: [
      'localhost',
      /^https:\/\/app.*\.cherre\.com/,
      /^https:\/\/dsp-hasura.*\.cherre\.com/,
      /^https:\/\/app.*\.web\.sandbox\.cherre.dev/,
    ],
    debug: false,
  });
}

const theme = createMuiTheme(muiThemeSettings);

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
redux.history.listen(({ action, location }) => {
  if (IS_SEGMENT_TRACKER_ENABLED) {
    analytics && analytics.page();
  }

  if (!location) {
    return;
  }

  // ignore some pages, there is another scroll logic
  if (location.pathname.match(/^\/core-api/)) {
    return;
  }

  if (location.pathname.match(/^\/core-prospect/)) {
    return;
  }

  if (action === 'PUSH') {
    window.scrollTo(0, 0);
  }
});

// Graphql hooks expects response to be wrapped in "data" object.
const gqlAxios = axios.create();

gqlAxios.interceptors.response.use((response) => {
  response.data = { data: response.data };

  return response;
});

export const graphqlClient = new GraphQLClient({
  url: '/api/v1/graphqlQuery',
  fetch: buildAxiosFetch(gqlAxios),
  cache: memCache(),
});

const generateClassName = createGenerateClassName({
  productionPrefix: 'c',
});

const postHogOptions = { api_host: 'https://app.posthog.com' };

const Root = () => (
  <Sentry.ErrorBoundary fallback={<span />} showDialog>
    <ReduxProvider store={redux.store}>
      <ConnectedRouter history={redux.history}>
        <StylesProvider generateClassName={generateClassName}>
          <MuiThemeProvider theme={theme}>
            <DataFetchingProvider
              config={dataFetchingConfig}
              registerAdditionalRelayEnvironmentsCallback={() => {
                const org_id = useSelector(
                  (state) => state?.user?.profile?.value?.organizationId
                );
                return org_id
                  ? {
                      dom_admin: () =>
                        Promise.resolve(
                          createDomEnvironment(org_id.toString())
                        ),
                    }
                  : undefined;
              }}
            >
              <SnackbarProvider>
                <AppContextProvider config={appContextConfig}>
                  <MuiPickersUtilsProvider utils={MomentUtils}>
                    <ConditionalProvider
                      Provider={PostHogProvider}
                      providerProps={{
                        apiKey: CHERRE_POSTHOG_KEY,
                        options: postHogOptions,
                      }}
                      renderProvider={() => {
                        const postHogEnabled = useFeatureFlag('PostHogEnabled');
                        return postHogEnabled;
                      }}
                    >
                      <ClientContext.Provider value={graphqlClient}>
                        <StyledComponentsProvider
                          theme={{
                            mainColors: [],
                            textColors: [],
                            fontFamily: [],
                            primary: theme.palette.primary,
                          }}
                        >
                          <CherreThemeProvider theme={themeV5}>
                            <Routes />
                          </CherreThemeProvider>
                        </StyledComponentsProvider>
                      </ClientContext.Provider>
                    </ConditionalProvider>
                  </MuiPickersUtilsProvider>
                </AppContextProvider>
              </SnackbarProvider>
            </DataFetchingProvider>
          </MuiThemeProvider>
        </StylesProvider>
      </ConnectedRouter>
    </ReduxProvider>
  </Sentry.ErrorBoundary>
);

export default hot(Root);
