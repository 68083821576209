import { makeStyles } from '@material-ui/core';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getViews } from 'src/products/core-prospect/redux/query/actions';
import SavedSearches from './SavedSearches';
import PropertyLists from './PropertyLists';
import { getPropertyLists } from 'src/products/core-prospect/redux/propertyLists/actions';

const useStyles = makeStyles(() => ({
  container: {
    paddingTop: 28,
    display: 'grid',
    gridTemplateColumns: 'repeat(auto-fill, minmax(auto, 430px))',
    alignItems: 'flex-start',
    gap: 23,
  },
}));

/**
 * SELECTORS
 */

const viewsSelector = (state: any): any => {
  return state.coreProspect.query.views
    ?.filter((view) => !view.view.deleted)
    .slice(0, 3);
};

const propertyListsSelector = (state: any): any => {
  return state.coreProspect.propertyLists.propertyLists.value
    ?.slice(0, 3)
    .map((propertyList) => ({
      name: propertyList.name,
      id: propertyList.id,
      properties: propertyList.entries_count,
    }));
};

const FavoritesLists: React.FC = () => {
  const classes = useStyles();
  const views = useSelector(viewsSelector);
  const propertyLists = useSelector(propertyListsSelector);
  const dispatch = useDispatch();
  React.useEffect(() => {
    dispatch(getViews());
    dispatch(getPropertyLists());
  }, []);
  if (!views.length && !propertyLists.length) {
    return null;
  }
  return (
    <div className={classes.container}>
      <SavedSearches views={views} />
      <PropertyLists propertyLists={propertyLists} />
    </div>
  );
};

FavoritesLists.displayName = 'FavoritesLists';

export default FavoritesLists;
