import axios, { AxiosError } from 'axios';
import { RootState } from 'react-redux';
import { Middleware } from 'redux';
import logException from 'src/utils/logException';

type ApiErrorData = { message: string; field: string; redirectUrl?: string }[];

class APIError extends Error {
  field?: string;
  errorId?: number;
  allErrors?: ApiErrorData;

  constructor(
    msg: string,
    field?: string,
    errorId?: number,
    allErrors?: ApiErrorData
  ) {
    super(msg);

    this.field = field;
    this.errorId = errorId;
    this.allErrors = allErrors;
  }
}

const cancelableRequestsURLsMap = new Map<string, AbortController>();

const createAbortController = (url: string) => {
  if (cancelableRequestsURLsMap.has(url)) {
    cancelableRequestsURLsMap.get(url)?.abort();
  }

  const controller = new AbortController();
  cancelableRequestsURLsMap.set(url, controller);
  return controller;
};

const apiMiddleware: Middleware<unknown, RootState> =
  (store) => (next) => async (action) => {
    if (!action) {
      return;
    }

    if (!action.url || action.type) {
      return next(action);
    }

    const [start, success, fail] = action.types ?? [];
    const url = action.url.match('http') ? action.url : '/api/v1' + action.url;

    start && store.dispatch({ type: start, ...action.dataToDispatch });

    const controller = action.cancelable
      ? createAbortController(url)
      : undefined;

    return axios({
      method: action.method ?? 'get',
      url,
      data: action.send,
      params: action.query,
      headers: action.headers,
      signal: controller?.signal,
    })
      .then((res) => {
        success &&
          store.dispatch({
            type: success,
            data: res.data,
            ...action.dataToDispatch,
          });
        return res.data;
      })
      .catch((error: Error | AxiosError) => {
        if (!axios.isAxiosError(error)) {
          throw error;
        }

        if (error.code === 'ERR_CANCELED') {
          return;
        }

        const response = error.response;
        const errorId = response?.data.errorId;

        if (!errorId) {
          throw error;
        }

        const allErrors: ApiErrorData = response?.data?.errors;
        const { redirectUrl, message, field } = allErrors?.[0];

        if (redirectUrl) {
          const redirect =
            redirectUrl === '/login'
              ? '/api/v1/auth/login?returnTo=' + window.location.pathname
              : redirectUrl;
          return (window.location.href = redirect);
        }

        const err = new APIError(message, field, errorId, allErrors);

        logException(err);

        fail &&
          store.dispatch({
            type: fail,
            error: err,
            errorId: errorId,
            ...action.dataToDispatch,
          });

        throw err;
      });
  };

export default apiMiddleware;
