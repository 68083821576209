import isEmpty from 'lodash/isEmpty';

export const length = (length) => (value) =>
  value.length >= length ? undefined : `Enter at least ${length} symbols`;

export const required = (value) => (value ? undefined : 'Required');

export const price = (value) => {
  return /^\$?\d+(,\d{3})*(\.\d*)?$/.test(value) ? undefined : 'Invalid number';
};

export const number = (value) => {
  return /^\d+(,\d{3})*(\.\d*)?$/.test(value) ? undefined : 'Invalid number';
};

export const isEmail = (value) => {
  if (!value) {
    return true;
  }

  const re = /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/;
  return re.test(value);
};

export const email = (email) => {
  return isEmail(email) ? undefined : 'Invalid email';
};

export function minLength(min) {
  return (value) => {
    if (!isEmpty(value) && value.length < min) {
      return `Must be at least ${min} characters`;
    }
    return false;
  };
}
export function maxLength(max) {
  return (value) => {
    if (!isEmpty(value) && value.length > max) {
      return `Must be no more than ${max} characters`;
    }
    return false;
  };
}

export function positiveInteger(n) {
  if (!(0 === n % (!isNaN(parseFloat(n)) && 0 <= ~~n))) {
    return 'Must be a postive Number';
  }
}

export function phone(value) {
  const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
  if (!phoneRegex.test(value)) {
    return 'Please enter a valid phone number';
  }
  return false;
}

export function lessThan(lt, value) {
  if (+value >= +lt) {
    return `Value should be less than ${lt}`;
  }
}

export function lessThanOrEqual(lte, value) {
  if (+value > +lte) {
    return `Value should be less than or equal to ${lte}`;
  }
}

export function greaterThan(gt, value) {
  if (+value <= +gt) {
    return `Value should be greater than ${gt}`;
  }
}

export function greaterThanOrEqual(gte, value) {
  if (+value < +gte) {
    return `Value should be greater than or equal to ${gte}`;
  }
}
