import * as types from './types';
import constants from 'src/const';

const initialState = {
  fetchStatus: {},
  fetchStatusByTableName: {},
  lookupsDictionary: {},
  lookupsByListName: {},
  lookupsByTableName: {},
};

const processLookupsByListName = (data) => {
  const lookupDictionary = {};

  data.forEach((lookup) => {
    if (!lookupDictionary[lookup.lookup_name]) {
      lookupDictionary[lookup.lookup_name] = [];
    }

    lookupDictionary[lookup.lookup_name].push(lookup);
  });

  return lookupDictionary;
};

const processLookupsByTableName = (data) => {
  const lookupsByTableName = {};

  data.forEach((lookup) => {
    const key = lookup.object_name + '.' + lookup.field_name;
    if (!lookupsByTableName[key]) {
      lookupsByTableName[key] = lookup.lookup_name;
    }
  });

  return lookupsByTableName;
};

const processLookups = (data) => {
  const lookupDictionary = {};
  data.forEach((lookup) => {
    const key = `${lookup.lookup_name}|${lookup.lookup_code}`;
    lookupDictionary[key] = lookup;
  });
  return lookupDictionary;
};

export default function lookupsReducer(state = initialState, action) {
  switch (action.type) {
    case types.GET_LOOKUPS_BY_TABLE_NAME_REQUEST: {
      return {
        ...state,
        fetchStatusByTableName: { value: constants.LOADING },
      };
    }

    case types.GET_LOOKUPS_BY_TABLE_NAME_SUCCESS: {
      const { lookups_by_table_name } = action.data;

      return {
        ...state,
        lookupsByTableName: processLookupsByTableName(lookups_by_table_name),
        fetchStatusByTableName: { value: constants.LOADED },
      };
    }

    case types.LOOKUPS_REQUEST:
      return {
        ...state,
        fetchStatus: {
          value: constants.LOADING,
        },
      };
    case types.LOOKUPS_SUCCESS: {
      const { lookups_by_list_name } = action.data;

      return {
        ...state,
        fetchStatus: {
          value: constants.LOADED,
        },
        lookupsDictionary: processLookups(lookups_by_list_name),
        lookupsByListName: processLookupsByListName(lookups_by_list_name),
      };
    }
    case types.LOOKUPS_FAILED:
      return {
        ...state,
        fetchStatus: {
          value: constants.FAILED,
        },
      };
    default:
      return state;
  }
}
