import {
  SAVE_QUERY_SUCCESS,
  EXEC_MAP_QUERY_SUCCESS,
  INCLUDE_CONDO_UNITS,
} from 'src/products/core-prospect/redux/query/types';
import {
  TOGGLE_CORE_PROSPECT_VIEW,
  OWNER_CLICKED,
} from 'src/products/core-prospect/redux/view/types';
import {
  CREATE_PROPERTY_LIST_SUCCESS,
  ADD_PROPERTY_LIST_ENTRY_SUCCESS,
} from 'src/products/core-prospect/redux/propertyLists/types';

import { CORE_PROSPECT_VIEWS } from 'src/products/core-prospect/constants';
import { getSearchParameters } from 'src/products/core-prospect/search-pages/selectors';
import {
  CSV_EXPORT_FAILED,
  CSV_WAS_EXPORTED,
  TABLE_RESULTS_SUCCESS,
} from 'src/products/core-prospect/redux/table/types';
import { getFilterWhereValue } from './utils/query';
import {
  DOWNLOAD_DATA_DICTIONARY,
  SET_CORE_PROSPECT_SELECTED,
  INVITE_MORE,
} from '../../pages/Home/redux/types';
import { REQUEST_SUBSCRIPTION } from '../actions/user';
import { GET_PIPL_CONTACT } from '../actions/common';
import { PRODUCTS } from 'src/const';

export const LOT_CARD_CLICKED = 'LOT_CARD_CLICKED';
export const OTHER_LOT_RECORD_CLICKED = 'OTHER_LOT_RECORD_CLICKED';
export const MAP_CLUSTER_CLICKED = 'MAP_CLUSTER_CLICKED';
export const MAP_PARCEL_CLICKED = 'MAP_PARCEL_CLICKED';
export const TAX_RECORD_MORE_INFO_CLICKED = 'TAX_RECORD_MORE_INFO_CLICKED';

// INCLUDE_CONDO_UNITS, areCondoUnitsIncluded

const filtersApplied = (state) => {
  const search_parameters = getSearchParameters(state);
  return [
    'Filters Applied',
    {
      search_parameters: search_parameters.map((sp) => ({
        entity_name: sp.entity_name,
        field_name: sp.field_name,
        operator: sp.attributes.operator,
        value: getFilterWhereValue(sp),
      })),
    },
  ];
};

const payload = {
  [LOT_CARD_CLICKED]: () => {
    return ['Lot card clicked', {}];
  },
  [OTHER_LOT_RECORD_CLICKED]: () => {
    return ['Other lot on preview clicked', {}];
  },
  [MAP_CLUSTER_CLICKED]: () => {
    return ['Map cluster clicked', {}];
  },
  [MAP_PARCEL_CLICKED]: () => {
    return ['Map parcel clicked', {}];
  },
  [TAX_RECORD_MORE_INFO_CLICKED]: () => {
    return ['More tax record info clicked', {}];
  },
  [INCLUDE_CONDO_UNITS]: (store, action) => {
    return [
      'Toggle condo units',
      {
        areCondoUnitsIncluded: action.areCondoUnitsIncluded,
      },
    ];
  },
  [GET_PIPL_CONTACT]: () => {
    return ['PIPL Contact Requested', {}];
  },
  [REQUEST_SUBSCRIPTION]: () => {
    return ['Clicked Subscribe', {}];
  },
  [INVITE_MORE]: () => {
    return ['User clicks Invite Members button on homepage', {}];
  },
  [DOWNLOAD_DATA_DICTIONARY]: () => {
    return ['User clicks the download dictionary button on the homepage', {}];
  },
  [SET_CORE_PROSPECT_SELECTED]: (store, action) => {
    return [
      'Homepage Product Tab Clicked',
      {
        tab: action.selected ? PRODUCTS.EXPLORE : PRODUCTS.QUERY,
      },
    ];
  },
  [CSV_EXPORT_FAILED]: (store, action) => {
    return [
      'CSV Export failed',
      {
        reason: action.reason,
      },
    ];
  },
  [CSV_WAS_EXPORTED]: (store, action) => {
    return [
      'CSV Exported',
      {
        remainingAttemps: action.remainingAttemp,
      },
    ];
  },
  [TABLE_RESULTS_SUCCESS]: filtersApplied,
  [EXEC_MAP_QUERY_SUCCESS]: filtersApplied,
  [OWNER_CLICKED]: (state, action) => {
    return [
      'Owner Clicked',
      {
        name: action.name,
        id: action.id,
      },
    ];
  },
  [TOGGLE_CORE_PROSPECT_VIEW]: (state) => {
    const currentView = state.coreProspect.view.viewerName;

    const newView =
      currentView === CORE_PROSPECT_VIEWS.MAP
        ? CORE_PROSPECT_VIEWS.TABLE
        : CORE_PROSPECT_VIEWS.MAP;

    return [
      'View Changed',
      {
        view: newView,
      },
    ];
  },
  [ADD_PROPERTY_LIST_ENTRY_SUCCESS]: (state, action) => {
    const lists = state.coreProspect.propertyLists.propertyLists.value;

    const list = lists.find((list) => list.id === action.data.list_id);

    return [
      'Property Added to List',
      {
        list_name: list?.name,
        property_code_type: action.data.property_code_type,
        property_id: action.data.property_id,
      },
    ];
  },
  [CREATE_PROPERTY_LIST_SUCCESS]: (store, action) => {
    return [
      'Property List Created',
      {
        list_name: action.data.name,
      },
    ];
  },
  [SAVE_QUERY_SUCCESS]: (store, action) => {
    return [
      'Search Saved',
      {
        name: action.data.view.name,
        entity_name: action.data.view.entity_name,
        search_parameters: action.data.search_parameters.map((sp) => ({
          entity_name: sp.entity_name,
          field_name: sp.field_name,
          operator: sp.attributes.operator,
          value: sp.attributes.value,
        })),
      },
    ];
  },
};

export default (store) => (dispatch) => (action) => {
  if (!action) {
    return;
  }

  if (!action.type) {
    return dispatch(action);
  }

  const getPayload = payload[action.type];

  if (getPayload) {
    const [event, properties] = getPayload(store.getState(), action);
    window.analytics && window.analytics.track(event, properties);
  }

  return dispatch(action);
};
