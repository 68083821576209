import { createClient } from 'graphql-ws';
import {
  Network,
  Observable,
  RequestParameters,
  Variables,
} from 'relay-runtime';
import { DSPHasuraJWT } from './jwt';

const createHttpClient = ({
  url,
  connectionParams,
}: {
  url: string;
  connectionParams: () => Promise<{
    headers?: Record<string, string>;
  }>;
}) => {
  return Network.create(
    async (operation, variables, _cacheConfig, _uploadables) => {
      const params = await connectionParams();
      return fetch(url, {
        method: 'POST',
        headers: params.headers,
        body: JSON.stringify({
          query: operation.text,
          variables,
        }),
      }).then((response) => {
        return response.json();
      });
    }
  );
};

export class GraphQLClient {
  private constructor() {
    throw new Error('GraphQLClient should not be instantiated');
  }
  private static instances = new Map<
    string,
    {
      http: ReturnType<typeof createHttpClient>;
      ws: ReturnType<typeof createClient>;
    }
  >();

  static getClient = async (key = 'default') => {
    const instance = GraphQLClient.instances.get(key);
    if (instance) {
      return instance;
    }

    const baseUrl = await DSPHasuraJWT.getBaseHasuraUrl();
    const wsProtocol = baseUrl.protocol === 'https:' ? 'wss' : 'ws';
    const httpProtocol = baseUrl.protocol === 'https:' ? 'https' : 'http';
    const wsUrl = `${wsProtocol}://${baseUrl.host}/v1/graphql`;
    const httpUrl = `${httpProtocol}://${baseUrl.host}/v1/graphql`;

    const connectionParams = async (): Promise<{
      headers: Record<string, string>;
    }> => {
      const jwt = await DSPHasuraJWT.getJwtToken();
      return {
        headers: {
          Authorization: `Bearer ${jwt}`,
          ...(key === 'default' ? undefined : { 'x-hasura-role': key }),
        },
      };
    };

    const newInstance = {
      ws: createClient({
        url: wsUrl,
        connectionParams,
      }),
      http: createHttpClient({
        url: httpUrl,
        connectionParams,
      }),
    };

    GraphQLClient.instances.set(key, newInstance);
    return newInstance;
  };

  static fetchOrSubscribe =
    (key = 'default') =>
    (
      operation: RequestParameters,
      variables: Variables
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ): Observable<any> => {
      const clients = GraphQLClient.instances.get(key);

      if (!clients) {
        throw new Error('Client is not initialized');
      }

      if (operation.operationKind !== 'subscription') {
        return clients.http.execute(operation, variables, {}, {});
      }

      return Observable.create((sink) => {
        if (!operation.text) {
          return sink.error(new Error('Operation text cannot be empty'));
        }

        return clients.ws.subscribe(
          {
            operationName: operation.name,
            query: operation.text,
            variables,
          },
          sink
        );
      });
    };
}
