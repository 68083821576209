export default [
  {
    title: 'GraphQL Query Operations',
    subtitle: 'Overview, Operators, Filtering',
    url: 'core-api#graphql-query-operations--2',
  },
  {
    title: 'Primary Keys',
    subtitle: 'Objects and their primary keys',
    url: 'core-api#primary-keys--5',
  },
  {
    title: 'Cherre Standardization Services',
    subtitle:
      'Address Standardization, Utilizing The Standardized Address, Connecting Addresses From Different Datasets',
    url: 'core-api#cherre-standardization-services--8',
  },
  {
    title: 'Foundation Data',
    subtitle: 'Objects, Query Samples, Pagination',
    url: 'core-api#available-objects--3',
  },
  {
    title: 'GraphQL Functions',
    subtitle: 'Basic functions, PostGIS functions',
    url: 'core-api#graphql-functions--7',
  },
];
