import * as TYPES from './types';
import * as FILTER_SELECTION_TYPES from '../filter-selection/types';
import _ from 'lodash';

const initialState = {
  unitsFilter: '',
};

function deleteResetFilterProperties(state, filters) {
  const filterIds = filters.map((filter) => filter.id);

  return _.omit(state, filterIds);
}

export default function filters(state = initialState, action) {
  switch (action.type) {
    case TYPES.RESET_FILTERS: {
      const updatedState = deleteResetFilterProperties(state, action.filters);

      return updatedState;
    }
    case TYPES.RESET_ALL_FILTERS:
    case FILTER_SELECTION_TYPES.TOGGLE_SEARCH_ENTITY:
      return initialState;

    case TYPES.CHANGE_FILTER_FIELD: {
      const { filter, value } = action;

      return {
        ...state,
        [filter.id]: value,
      };
    }
    case TYPES.CHANGE_UNITS_FILTER:
      return {
        ...state,
        unitsFilter: action.value,
      };
    default:
      return state;
  }
}
