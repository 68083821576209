import * as types from './types';

export const setCoreProspectSelected = (selected) => {
  return {
    type: types.SET_CORE_PROSPECT_SELECTED,
    selected,
  };
};

// for segment
export const downloadDataDictionary = () => {
  return {
    type: types.DOWNLOAD_DATA_DICTIONARY,
  };
};

// for segment
export const inviteMore = () => {
  return {
    type: types.INVITE_MORE,
  };
};

export const updateUserFirstTimeLogin = () => {
  return {
    url: '/organizations/users/first_time_login',
    method: 'put',
    types: [
      types.UPDATE_USER_FIRST_TIME_LOGIN_REQUEST,
      types.UPDATE_USER_FIRST_TIME_LOGIN_SUCCESS,
      types.UPDATE_USER_FIRST_TIME_LOGIN_FAILED,
    ],
  };
};
