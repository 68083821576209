import axios from 'axios';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { atom } from 'recoil';

interface TokenResponse {
  token: string;
  baseUrl: string;
}

interface HttpsHasuraIoJwtClaims {
  'x-hasura-default-role': string;
  'x-hasura-allowed-roles': string[];
  'x-hasura-user-id': string;
  'x-hasura-org-id': string;
}

interface DecodedToken extends JwtPayload {
  'https://hasura.io/jwt/claims': HttpsHasuraIoJwtClaims;
}

const hasuraAuthUrl = '/api/v1/dsp/hasura-token';

export class DSPHasuraJWT {
  private static jwt: DecodedToken | null = null;
  private static rawToken: string | null = null;
  private static hasuraUrl: URL | null = null;
  private static setSelf: ((roles: string[]) => void) | null = null;
  private static autoFetchHasuraTokenTimeoutId: ReturnType<
    typeof setTimeout
  > | null = null;
  public static rolesAtom = atom<string[]>({
    key: 'DSPHasuraJWT/roles',
    effects: [
      (params) => {
        this.setSelf = params.setSelf;
        if (params.trigger === 'get') {
          const roles = this.getUserAllowedRoles();
          if (!roles) {
            params.setSelf(new Promise(() => {}));
            this.fetchHasuraToken();
          } else {
            params.setSelf(roles);
          }
        }
        return () => {
          this.setSelf = null;
        };
      },
    ],
  });

  private constructor() {
    throw new Error('DSPHasuraJWT should not be instantiated');
  }

  private static decodeToken = (token: string) => {
    try {
      DSPHasuraJWT.jwt = jwtDecode(token);
      const roles = DSPHasuraJWT.getUserAllowedRoles();
      if (DSPHasuraJWT.setSelf && roles) {
        DSPHasuraJWT.setSelf(roles);
      }
    } catch (e) {
      console.error('Could not decode JWT', e);
      DSPHasuraJWT.rawToken = null;
      DSPHasuraJWT.jwt = null;
    }
  };
  static getUserId = () => {
    const userId =
      DSPHasuraJWT.jwt?.['https://hasura.io/jwt/claims']['x-hasura-user-id'];
    return userId ? Number(userId) : null;
  };

  static getUserAllowedRoles = () => {
    const roles =
      DSPHasuraJWT.jwt?.['https://hasura.io/jwt/claims'][
        'x-hasura-allowed-roles'
      ];
    return roles;
  };

  static getJwtToken = async (): Promise<string> => {
    if (DSPHasuraJWT.rawToken == null || DSPHasuraJWT.hasJWTExpired()) {
      await DSPHasuraJWT.fetchHasuraToken();
    }
    if (DSPHasuraJWT.rawToken == null) {
      throw new Error('Could not fetch Hasura token');
    }
    return DSPHasuraJWT.rawToken;
  };

  static getBaseHasuraUrl = async (): Promise<URL> => {
    if (DSPHasuraJWT.hasuraUrl == null) {
      await DSPHasuraJWT.fetchHasuraToken();
    }
    if (DSPHasuraJWT.hasuraUrl == null) {
      throw new Error('Could not fetch Hasura URL');
    }
    return DSPHasuraJWT.hasuraUrl;
  };

  static getDecodedToken = (): DecodedToken => {
    if (!DSPHasuraJWT.jwt) {
      DSPHasuraJWT.fetchHasuraToken();
    }
    if (DSPHasuraJWT.jwt == null) {
      throw new Error('Could not decode Hasura token');
    }
    return DSPHasuraJWT.jwt;
  };

  private static fetchHasuraToken = async (): Promise<void> => {
    const res = await axios.get<TokenResponse>(hasuraAuthUrl);
    const { token, baseUrl } = res.data;

    DSPHasuraJWT.rawToken = token;
    DSPHasuraJWT.decodeToken(token);
    DSPHasuraJWT.hasuraUrl = new URL(baseUrl);

    // auto refresh token before it expires
    if (DSPHasuraJWT.jwt?.exp) {
      // clear timeout if it exists
      if (DSPHasuraJWT.autoFetchHasuraTokenTimeoutId) {
        clearTimeout(DSPHasuraJWT.autoFetchHasuraTokenTimeoutId);
      }
      // set timeout to refresh token
      const gracePeriod = 60; // seconds
      const subtrahend = Date.now() / 1000 + gracePeriod;
      const delayInSeconds = Math.max(DSPHasuraJWT.jwt.exp - subtrahend, 0); // handle negative values
      DSPHasuraJWT.autoFetchHasuraTokenTimeoutId = setTimeout(
        DSPHasuraJWT.fetchHasuraToken,
        delayInSeconds * 1000
      );
    }
  };

  private static hasJWTExpired = (): boolean => {
    if (!DSPHasuraJWT.jwt || !DSPHasuraJWT.jwt?.exp) {
      return true;
    }
    const limit = Date.now() / 1000 + 30; // Add 30 seconds to limit
    return limit > DSPHasuraJWT.jwt.exp;
  };
}
