import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { CreateDashboard } from 'src/products/core-bi/CoreBIDashboardsDrawer/CreateDashboard';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    width: 203,
    height: 180,
    borderRadius: 6,
    border: `2px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    cursor: 'pointer',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: 5,
  },
}));

export const CreateDashboardCard: React.FC = () => {
  const classes = useStyles();

  return (
    <CreateDashboard>
      {({ open }) => (
        <div onClick={open} className={classes.cardContainer}>
          <i className={`icon-ico-dashboards ${classes.icon}`} />
          <Typography className={classes.title} color='primary' variant='h5'>
            Create New
          </Typography>
        </div>
      )}
    </CreateDashboard>
  );
};

CreateDashboardCard.displayName = 'CreateDashboardCard';
