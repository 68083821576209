import React, { useCallback } from 'react';
import {
  Button,
  createMuiTheme,
  MuiThemeProvider,
  Typography,
  useTheme,
} from '@material-ui/core';
import { useSnackbar as useSnackbarDefault } from 'notistack';
import {
  SnackbarProps,
  SnackbarAction,
  createAppConfig,
  BrowserTelemetry,
} from '@cherre-frontend/core';
import { usePermission } from 'src/hooks/usePermission';
import { CherreThemeProvider } from '@cherre-frontend/ui';
import { themeV5 } from 'src/const/themev5';

const snackbarHook = () => {
  const { enqueueSnackbar, closeSnackbar } = useSnackbarDefault();

  const theme = useTheme();

  return useCallback((props: SnackbarProps) => {
    const handle = enqueueSnackbar(
      <MuiThemeProvider theme={createMuiTheme(theme)}>
        <Typography>{props.message}</Typography>
      </MuiThemeProvider>,
      {
        variant: props.type,
        autoHideDuration: props.autoHideDuration ?? 5000,
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
        content: props.content
          ? (key: number, message: string) =>
              typeof props.content === 'function' ? (
                <div>
                  <CherreThemeProvider theme={themeV5}>
                    {props.content?.(key, message)}
                  </CherreThemeProvider>
                </div>
              ) : (
                props.content
              )
          : undefined,
        action: props.buttons
          ? (snackbarId: number) => (
              <>
                {props.buttons?.map((button: SnackbarAction) => (
                  <Button
                    color={button.type}
                    variant={button.variant}
                    size='small'
                    onClick={() => button.onClick(snackbarId)}
                  >
                    {button.title}
                  </Button>
                ))}
              </>
            )
          : undefined,
      }
    );
    return () => closeSnackbar(handle);
  }, []);
};

class TelemetryImpl extends BrowserTelemetry {
  public trackEvent(
    name: string,
    context?: Record<string, string | number> | undefined
  ): void {
    window.analytics?.track(name, context);
  }
}

export const appContextConfig = createAppConfig({
  exposeWindowHooks: () => usePermission('CherreDev'),
  snackbarHook,
  telemetryClass: TelemetryImpl,
});
