export const TOGGLE_FILTER_GROUP = 'TOGGLE_FILTER_GROUP';
export const TOGGLE_FILTER_SET = 'TOGGLE_FILTER_SET';
export const TOGGLE_SEARCH_ENTITY = 'TOGGLE_SEARCH_ENTITY';
export const SELECT_RESULTS_VIEW_MODE = 'SELECT_RESULTS_VIEW_MODE';
export const SELECT_MAP_RESULT = 'SELECT_MAP_RESULT';
export const TOGGLE_FILTER = 'TOGGLE_FILTER';
export const CHANGE_OFFSET = 'CHANGE_OFFSET';
export const CHANGE_ORDER_BY = 'CHANGE_ORDER_BY';
export const TOGGLE_DISCOVERY_MODE = 'TOGGLE_DISCOVERY_MODE';
export const TOGGLE_EDITING_MODE = 'TOGGLE_EDITING_MODE';
export const OPEN_FILTER = 'OPEN_FILTER';
