import React from 'react';
import logException from 'src/utils/logException';
import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import Button from '@material-ui/core/Button';

class NotFound extends React.Component {
  componentDidMount() {
    logException(new Error(JSON.stringify(this.props.location)));
  }

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root}>
        <div className={classes.content}>
          <img
            src='/assets/cherre-logo-white-with-text.svg'
            className={classes.logo}
          />
          <h1>404</h1>
          <h4>We couldn’t find the page you’re looking for.</h4>
          <Button
            className={classes.button}
            onClick={() => (document.location.href = '/')}
          >
            Take me back
          </Button>
          <img src='/assets/404-artwork.svg' className={classes.artWork} />
        </div>
        <div className={classes.wave} />
        <div className={classes.footer}>
          © 2019 Cherre Inc. All rights reserved.
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(NotFound);
