import findIndex from 'lodash/findIndex';
import * as types from './types';

import constants from 'src/const';
const { LOADING, LOADED, FAILED } = constants;

const initialState = {
  tablesRelations: [],
  tablesRelationsIndex: {},
  tablesRelationsFetchStatus: {},

  filterGroups: [],
  filterGroupsFetchStatus: {},

  filterSets: [],
  filterSetsFetchStatus: {},

  filters: [],
  filtersFetchStatus: {},

  filterGroupPermissions: [],
  filterGroupPermissionsFetchStatus: {},

  filterTemplateFetchStatus: {},
  filterTemplates: [],

  columnDefinitions: [],
  columnDefinitionsFetchStatus: {},

  filterTypes: [],
  entities: [],
  entitiesFetchStatus: {},

  fields: [],

  entitiesEntitiesFields: {},
};

const createTablesRelationsIndex = (tablesRelations) => {
  const tablesRelationsIndex = {};

  tablesRelations.forEach((currentTableRelation) => {
    tablesRelationsIndex[
      `${currentTableRelation.parent}|${currentTableRelation.node}`
    ] = currentTableRelation.type;
  }, {});

  return tablesRelationsIndex;
};

export default function filtersConfigData(state = initialState, action) {
  switch (action.type) {
    case types.GET_TABLE_RELATIONS_REQUEST: {
      return {
        ...state,
        tablesRelationsFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.GET_TABLE_RELATIONS_SUCCESS: {
      return {
        ...state,
        tablesRelations: action.data,
        tablesRelationsIndex: createTablesRelationsIndex(action.data),
        tablesRelationsFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_TABLE_RELATIONS_FAILED: {
      return {
        ...state,
        tablesRelationsFetchStatus: {
          value: FAILED,
        },
      };
    }

    case types.SAVE_FILTER_SUCCESS: {
      const filter = action.data.data;

      const { filters } = state;

      const filterIndex = findIndex(filters, (set) => set.id === filter.id);

      if (filterIndex < 0) {
        // add new filter
        return {
          ...state,
          filters: filters.concat(filter),
        };
      }

      return {
        ...state,
        filters: [
          ...filters.slice(0, filterIndex),
          filter,
          ...filters.slice(filterIndex + 1),
        ],
      };
    }

    case types.SAVE_FILTER_SET_SUCCESS: {
      const filterSet = action.data.data;

      const { filterSets } = state;

      const setIndex = findIndex(filterSets, (set) => set.id === filterSet.id);

      if (setIndex < 0) {
        // add new set
        return {
          ...state,
          filterSets: filterSets.concat(filterSet),
        };
      }

      return {
        ...state,
        filterSets: [
          ...filterSets.slice(0, setIndex),
          filterSet,
          ...filterSets.slice(setIndex + 1),
        ],
      };
    }

    case types.SAVE_FILTER_GROUP_SUCCESS: {
      const filterGroup = action.data.data;

      const { filterGroups } = state;

      const groupIndex = findIndex(
        filterGroups,
        (group) => group.id === filterGroup.id
      );

      if (groupIndex < 0) {
        // add new group
        return {
          ...state,
          filterGroups: filterGroups.concat(filterGroup),
        };
      }

      return {
        ...state,
        filterGroups: [
          ...filterGroups.slice(0, groupIndex),
          filterGroup,
          ...filterGroups.slice(groupIndex + 1),
        ],
      };
    }

    case types.GET_COLUMN_DEFINITION_REQUEST: {
      return {
        ...state,
        columnDefinitionsFetchStatus: {
          value: LOADING,
        },
      };
    }
    case types.GET_COLUMN_DEFINITION_SUCCESS: {
      return {
        ...state,
        columnDefinitions: action.data.data,
        columnDefinitionsFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_FILTER_GROUP_PERMISSIONS_REQUEST: {
      return {
        ...state,
        filterGroupPermissionsFetchStatus: {
          value: LOADING,
        },
      };
    }
    case types.GET_FILTER_GROUP_PERMISSIONS_SUCCESS: {
      return {
        ...state,
        filterGroupPermissions: action.data.data,
        filterGroupPermissionsFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_FILTER_GROUPS_REQUEST: {
      return {
        ...state,
        filterGroupsFetchStatus: {
          value: LOADING,
        },
      };
    }
    case types.GET_FILTER_GROUPS_SUCCESS: {
      return {
        ...state,
        filterGroups: action.data.data,
        filterGroupsFetchStatus: {
          value: LOADED,
        },
      };
    }
    case types.GET_FILTER_TEMPLATE_REQUEST: {
      return {
        ...state,
        filterTemplateFetchStatus: {
          value: LOADING,
        },
      };
    }
    case types.GET_FILTER_TEMPLATE_SUCCESS: {
      return {
        ...state,
        filterTemplates: action.data.data,
        filterTemplateFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_FILTER_SETS_REQUEST: {
      return {
        ...state,
        filterSetsFetchStatus: {
          value: LOADING,
        },
      };
    }
    case types.GET_FILTER_SETS_SUCCESS: {
      return {
        ...state,
        filterSets: action.data.data,
        filterSetsFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_FILTERS_REQUEST: {
      return {
        ...state,
        filtersFetchStatus: {
          value: LOADING,
        },
      };
    }
    case types.GET_FILTERS_SUCCESS: {
      return {
        ...state,
        filters: action.data.data,
        filtersFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_FILTER_TYPES: {
      return {
        ...state,
        filterTypes: action.filterTypes,
      };
    }

    case types.GET_ENTITIES_REQUEST: {
      return {
        ...state,
        entitiesFetchStatus: {
          value: LOADING,
        },
      };
    }

    case types.GET_ENTITIES_SUCCESS: {
      return {
        ...state,
        entities: action.data.__schema.queryType.fields,
        entitiesFetchStatus: {
          value: LOADED,
        },
      };
    }

    case types.GET_ENTITY_FIELDS_SUCCESS: {
      return {
        ...state,
        fields: action.data.__type.fields,
      };
    }

    default:
      return state;
  }
}
