export const GET_TABLE_RELATIONS_REQUEST = 'GET_TABLE_RELATIONS_REQUEST';
export const GET_TABLE_RELATIONS_SUCCESS = 'GET_TABLE_RELATIONS_SUCCESS';
export const GET_TABLE_RELATIONS_FAILED = 'GET_TABLE_RELATIONS_FAILED';

export const GET_FILTER_GROUP_PERMISSIONS_REQUEST =
  'GET_FILTER_GROUP_PERMISSIONS_REQUEST';
export const GET_FILTER_GROUP_PERMISSIONS_SUCCESS =
  'GET_FILTER_GROUP_PERMISSIONS_SUCCESS';
export const GET_FILTER_GROUP_PERMISSIONS_FAILED =
  'GET_FILTER_GROUP_PERMISSIONS_FAILED';

export const GET_FILTER_GROUPS_REQUEST = 'GET_FILTER_GROUPS_REQUEST';
export const GET_FILTER_GROUPS_SUCCESS = 'GET_FILTER_GROUPS_SUCCESS';
export const GET_FILTER_GROUPS_FAILED = 'GET_FILTER_GROUPS_FAILED';

export const GET_FILTER_TEMPLATE_REQUEST = 'GET_FILTER_TEMPLATE_REQUEST';
export const GET_FILTER_TEMPLATE_SUCCESS = 'GET_FILTER_TEMPLATE_SUCCESS';
export const GET_FILTER_TEMPLATE_FAILED = 'GET_FILTER_TEMPLATE_FAILED';

export const GET_FILTER_SETS_REQUEST = 'GET_FILTER_SETS_REQUEST';
export const GET_FILTER_SETS_SUCCESS = 'GET_FILTER_SETS_SUCCESS';
export const GET_FILTER_SETS_FAILED = 'GET_FILTER_SETS_FAILED';

export const GET_FILTERS_REQUEST = 'GET_FILTERS_REQUEST';
export const GET_FILTERS_SUCCESS = 'GET_FILTERS_SUCCESS';
export const GET_FILTERS_FAILED = 'GET_FILTERS_FAILED';

export const GET_FILTERS = 'GET_FILTERS';

export const GET_FILTER_TYPES = 'GET_FILTER_TYPES';

export const GET_ENTITIES_REQUEST = 'GET_ENTITIES_REQUEST';
export const GET_ENTITIES_SUCCESS = 'GET_ENTITIES_SUCCESS';
export const GET_ENTITIES_FAILED = 'GET_ENTITIES_FAILED';

export const GET_ENTITY_FIELDS_REQUEST = 'GET_ENTITY_FIELDS_REQUEST';
export const GET_ENTITY_FIELDS_SUCCESS = 'GET_ENTITY_FIELDS_SUCCESS';
export const GET_ENTITY_FIELDS_FAILED = 'GET_ENTITY_FIELDS_FAILED';

export const SAVE_FILTER_GROUP_SUCCESS = 'SAVE_FILTER_GROUP_SUCCESS';
export const SAVE_FILTER_GROUP_FAILED = 'SAVE_FILTER_GROUP_FAILED';

export const SAVE_FILTER_SET_SUCCESS = 'SAVE_FILTER_SET_SUCCESS';
export const SAVE_FILTER_SET_FAILED = 'SAVE_FILTER_SET_FAILED';

export const SAVE_FILTER_SUCCESS = 'SAVE_FILTER_SUCCESS';
export const SAVE_FILTER_FAILED = 'SAVE_FILTER_FAILED';

export const GET_COLUMN_DEFINITION_REQUEST = 'GET_COLUMN_DEFINITION_REQUEST';
export const GET_COLUMN_DEFINITION_SUCCESS = 'GET_COLUMN_DEFINITION_SUCCESS';
export const GET_COLUMN_DEFINITION_FAILED = 'GET_COLUMN_DEFINITION_FAILED';
