import React from 'react';

import { useHistory } from 'react-router';
import { styled, Typography, Tooltip, Chip, Box } from '@cherre-frontend/ui';
import { PowerBIEnvironment } from 'src/products/core-bi-v2/types';

const CardContainer = styled('div')`
  width: 203px;
  height: 160px;
  background-color: #fff;
  box-shadow: 0 2px 5px 3px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
  overflow: hidden;
  cursor: pointer;
`;

const Title = styled(Typography)`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.2em;
  max-height: 2.4em;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  color: #212121;
  overflow: hidden;
  text-overflow: ellipsis;
`;

interface ReportCardProps {
  id: string;
  name: string;
  workspaceId: string;
  workspaceName: string;
  environment?: PowerBIEnvironment;
}

export const ReportCard: React.FC<ReportCardProps> = (props) => {
  const history = useHistory();

  const title = props.name;
  const env = props.environment;

  return (
    <CardContainer onClick={() => history.push(`reports/${props.id}`)}>
      <img
        src={`/api/v1/powerbi/thumbnail/${props.workspaceId}/${props.id}`}
        onError={({ currentTarget }) => {
          currentTarget.src =
            'https://cherre.com/web-assets/img/connections/logos/fallback.svg';
        }}
        style={{ filter: 'brightness(90%)', transition: 'all .2s ease' }}
        width={203}
        height={100}
      />
      <Box
        padding={'10px'}
        alignItems={'center'}
        flexDirection={'row'}
        display={'flex'}
      >
        {env?.label && (
          <Tooltip
            disableInteractive
            leaveTouchDelay={0}
            title={props.workspaceName}
          >
            <Chip
              style={{ marginRight: 10 }}
              variant='outlined'
              size='small'
              label={env.label}
            />
          </Tooltip>
        )}
        <Tooltip disableInteractive leaveTouchDelay={0} title={title}>
          <Title>{title}</Title>
        </Tooltip>
      </Box>
    </CardContainer>
  );
};

ReportCard.displayName = 'HomePageDashboardCard';
