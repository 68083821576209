import { styled, Typography } from '@cherre-frontend/ui';
import { Grid as VirtualizedGrid } from 'react-virtualized';
import { NoDashboardsCard } from '../DashboardsSelector/NoDashboardsCard';

export const Container = styled('div')`
  display: grid;
  grid-template-columns: 36px auto 1fr auto 36px;
  grid-template-areas:
    'title title . view-all view-all'
    'arrow-left list list list arrow-right';
  align-items: center;
  margin: 0px -18px;
`;

export const Title = styled(Typography)`
  grid-area: title;
  font-size: 32px;
  padding-left: 18px;
`;

export const ViewAll = styled('div')`
  grid-area: view-all;
  color: ${({ theme }) => theme.palette.primary.main};
  cursor: pointer;
  font-size: 14px;
  display: grid;
  align-items: center;
  grid-template-columns: auto auto;
  padding-right: 18px;
  gap: 5px;

  & svg {
    font-size: 16px;
  }
`;

export const List = styled('div')`
  grid-area: list;
  justify-content: flex-start;
  overflow: hidden;
  padding: 20px 5px;
  margin: 0px -18px;
`;
const Arrow = styled('div')`
  width: 36px;
  height: 36px;
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  display: grid;
  place-items: center;
  z-index: 10;
`;

export const ArrowRight = styled(Arrow)`
  grid-area: arrow-right;
`;
export const ArrowLeft = styled(Arrow)`
  grid-area: arrow-left;
`;

export const Grid = styled(VirtualizedGrid)`
  overflow: hidden !important;
`;

export const NoReportsCard = styled(NoDashboardsCard)`
  height: 80px;

  h5 {
    margin-top: 2px;
  }
`;
