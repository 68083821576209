import React from 'react';
import AccessDenied from './components/AccessDenied';
import { Typography, Button } from '@material-ui/core';

export default () => {
  return (
    <AccessDenied title='Error'>
      <Typography variant={'body1'}>
        This account has been disabled or hasn't been activated yet.
      </Typography>

      <Typography variant={'body1'}>
        For further information, please contact your account manager.
      </Typography>
      <br />
      <br />
      <a href='/api/v1/auth/logout'>
        <Button color='primary'>Return to login</Button>
      </a>
    </AccessDenied>
  );
};
