import { CALL_HISTORY_METHOD, routerActions } from 'connected-react-router';

export default () => (next) => (action) => {
  if (action?.type !== CALL_HISTORY_METHOD) {
    return next(action);
  }
  if (typeof action.payload?.args?.[0] !== 'object') {
    return next(action);
  }
  const params = action.payload.args[0];
  const queryEntries = Array.from(Object.entries(params.query || {})).filter(
    ([, value]) => value !== undefined
  );
  const url =
    params.pathname +
    '?' +
    new URLSearchParams(
      Object.fromEntries(queryEntries) as Record<string, string>
    );
  return next(routerActions[action.payload.method](url));
};
