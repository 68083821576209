import axios from 'axios';
import { FetchFunction, Observable } from 'relay-runtime';

export const fetchQuery: (key?: string) => FetchFunction =
  (key) => (operation, variables) => {
    const headers = {
      'content-type': 'application/json',
    };
    if (key) {
      headers['x-sakura-org-id'] = key;
    }
    const response = axios.post(
      '/api/v1/dom/graphql',
      {
        query: operation.text,
        variables,
      },
      { headers }
    );

    return Observable.from(response.then(({ data }) => data));
  };
