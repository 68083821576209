import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';
import { useDispatch } from 'react-redux';
import { push } from 'connected-react-router';
import { useRecoilValueLoadable } from 'recoil';
import { dashboardThumbnailAtom } from '../../recoil';

const useStyles = makeStyles(() => ({
  cardContainer: {
    width: 203,
    height: 180,
    boxShadow: '0 2px 5px 3px rgba(0, 0, 0, 0.1)',
    borderRadius: 6,
    overflow: 'hidden',
    cursor: 'pointer',
  },
  cardUpperDiv: {
    width: '100%',
    height: 100,
    backgroundColor: 'rgb(246, 246, 247)',
  },
  cardLowerDiv: {
    width: '100%',
    height: 80,
    padding: 10,
    backgroundColor: '#fff',
  },
  title: {
    fontSize: 14,
    fontWeight: 600,
    color: '#212121',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  subtitle: {
    fontSize: 12,
    fontWeight: 500,
    color: '#616161',
  },
}));

interface DashboardCardProps {
  name: string;
  slug: string;
}

export const DashboardCard: React.FC<DashboardCardProps> = (props) => {
  const classes = useStyles();

  const title = props.name.split(' (')[0];
  const subTitle = props.name.match(/\(.*\)/gi);
  const organizations = subTitle?.[0].replace(/(\(|\))/gi, '').split(', ');

  const dispatch = useDispatch();

  const thumbnail = useRecoilValueLoadable(dashboardThumbnailAtom(props.slug));

  const __html = thumbnail.state === 'hasValue' ? thumbnail.contents : '';

  return (
    <div
      className={classes.cardContainer}
      onClick={() => dispatch(push(`dashboards/${props.slug}`))}
    >
      <div
        className={classes.cardUpperDiv}
        dangerouslySetInnerHTML={{ __html }}
      />
      <div className={classes.cardLowerDiv}>
        <Typography className={classes.title}>{title}</Typography>
        <Typography noWrap className={classes.subtitle}>
          {organizations?.join(', ')}
        </Typography>
      </div>
    </div>
  );
};

DashboardCard.displayName = 'HomePageDashboardCard';
