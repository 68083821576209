import React, { Suspense } from 'react';
import { connect } from 'react-redux';
import NavBar from 'src/components/NavBar';
import { LinearProgress } from '@material-ui/core';

type AppLayoutProps = {
  isEmulatedSession?: boolean;
  userId?: number;
  children: React.ReactNode;
};

const AppLayout: React.FC<AppLayoutProps> = (props) => {
  return (
    <React.Fragment>
      <div
        style={{
          display: 'grid',
          width: '100vw',
          height: '100vh',
          gridTemplateRows: 'auto 1fr',
        }}
      >
        {props.userId && <NavBar isEmulatedSession={props.isEmulatedSession} />}
        <Suspense fallback={<LinearProgress />}>
          <div
            id='app-content'
            style={{
              position: 'relative',
              zIndex: 1,
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'auto',
            }}
          >
            {props.children}
          </div>
        </Suspense>
      </div>
    </React.Fragment>
  );
};

function mapStateToProps(state) {
  const {
    user: {
      profile: {
        value: { isEmulatedSession, id },
      },
    },
  } = state;

  return {
    isEmulatedSession,
    userId: id,
  };
}

export default connect(mapStateToProps)(AppLayout);
