import * as types from './types';
import * as filterTypes from '../filters/types';

const initialState = {
  isDiscoveryMode: true,
  isEditingMode: false,
  searchEntityName: 'nyc_building',
  filterGroupId: 1,
  filterSetId: 17,
  filterId: 93,
  mapViewMode: 'regular',
  resultsViewMode: 'map',
  orderField: '',
  orderDirection: '',
  searchFiltersText: '',
};

export default function filterSelection(state = initialState, action) {
  switch (action.type) {
    case types.OPEN_FILTER: {
      return {
        ...state,
        filterId: action.filterId,
        filterSetId: action.filterSetId,
        filterGroupId: action.filterGroupId,
      };
    }

    case types.TOGGLE_EDITING_MODE: {
      return {
        ...state,
        filterGroupId: null,
        filterSetId: null,
        filterId: null,
        isEditingMode: !state.isEditingMode,
      };
    }

    case types.TOGGLE_DISCOVERY_MODE: {
      return {
        ...state,
        isDiscoveryMode: !state.isDiscoveryMode,
      };
    }

    case types.CHANGE_ORDER_BY: {
      return {
        ...state,
        orderField: action.orderField,
        orderDirection: action.orderDirection,
      };
    }

    case types.SELECT_RESULTS_VIEW_MODE:
      return {
        ...state,
        resultsViewMode: action.mode,
      };

    case filterTypes.RESET_ALL_FILTERS:
      return {
        ...initialState,
        isDiscoveryMode: state.isDiscoveryMode,
      };

    case types.TOGGLE_SEARCH_ENTITY:
      return {
        ...initialState,
        searchEntityName: action.value,
        isDiscoveryMode: state.isDiscoveryMode,
      };

    case types.TOGGLE_FILTER_GROUP:
      return {
        ...state,
        filterGroupId: action.value,
        filterSetId: null,
        filterId: null,
      };
    case types.TOGGLE_FILTER_SET:
      return {
        ...state,
        filterSetId: action.value,
        filterId: null,
      };
    case types.TOGGLE_FILTER:
      return {
        ...state,
        filterId: action.value,
      };
    default:
      return state;
  }
}
