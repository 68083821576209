import { Typography } from '@material-ui/core';
import { styled } from '@material-ui/styles';
import React from 'react';

const Container = styled('div')({
  padding: 10,
  borderRadius: 6,
  border: 'solid 1px #e0e0e0',
  backgroundColor: '#fff',
  cursor: 'pointer',
  display: 'grid',
  gap: 3,
});

const Title = styled(Typography)({
  fontSize: 14,
  fontWeight: 600,
  color: '#212121',
});

const Subtitle = styled(Typography)({
  fontSize: 12,
  fontWeight: 500,
  color: '#616161',
});

interface FavoriteCardProps {
  title: string;
  subtitle: React.ReactNode;
  onClick: () => void;
}

const FavoriteCard: React.FC<FavoriteCardProps> = (props) => (
  <Container onClick={props.onClick}>
    <Title>{props.title}</Title>
    <Subtitle>{props.subtitle}</Subtitle>
  </Container>
);

FavoriteCard.displayName = 'FavoriteCard';

export default FavoriteCard;
