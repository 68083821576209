import React from 'react';
import ReactDOM from 'react-dom';
import { IS_SERVICE_WORKER_ENABLED } from 'src/const';

import Root from '../../Root';

if (module.hot) {
  module.hot.accept();
}

if ('serviceWorker' in navigator) {
  if (IS_SERVICE_WORKER_ENABLED) {
    window.addEventListener('load', () => {
      navigator.serviceWorker
        .register('/static/service-worker.js', { scope: '/' })
        .then((registration) => {
          console.log('SW registered: ', registration);
        })
        .catch((registrationError) => {
          console.log('SW registration failed: ', registrationError);
        });
    });
  }
}

ReactDOM.render(<Root />, document.getElementById('root'));
