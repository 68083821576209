export const READ_NOTIFICATIONS_REQUEST = 'READ_NOTIFICATIONS_REQUEST';
export const READ_NOTIFICATIONS_SUCCESS = 'READ_NOTIFICATIONS_SUCCESS';
export const READ_NOTIFICATIONS_FAILED = 'READ_NOTIFICATIONS_FAILED';

export const UNREAD_NOTIFICATIONS_COUNT_REQUEST =
  'UNREAD_NOTIFICATIONS_COUNT_REQUEST';
export const UNREAD_NOTIFICATIONS_COUNT_SUCCESS =
  'UNREAD_NOTIFICATIONS_COUNT_SUCCESS';
export const UNREAD_NOTIFICATIONS_COUNT_FAILED =
  'UNREAD_NOTIFICATIONS_COUNT_FAILED';

export const NOTIFICATIONS_REQUEST = 'NOTIFICATIONS_REQUEST';
export const NOTIFICATIONS_SUCCESS = 'NOTIFICATIONS_SUCCESS';
export const NOTIFICATIONS_FAILED = 'NOTIFICATIONS_FAILED';

export const GET_MORE_NOTIFICATIONS_REQUEST = 'GET_MORE_NOTIFICATIONS_REQUEST';
export const GET_MORE_NOTIFICATIONS_SUCCESS = 'GET_MORE_NOTIFICATIONS_SUCCESS';
export const GET_MORE_NOTIFICATIONS_FAILED = 'GET_MORE_NOTIFICATIONS_FAILED';
