import { Environment, Network, RecordSource, Store } from 'relay-runtime';
import { fetchQuery } from './GraphQLClient';

// Create a network layer from the fetch function

const network = Network.create(fetchQuery());

export const DomEnvironment = new Environment({
  network,
  store: new Store(new RecordSource()),
});

const environmentMap = new Map<string, Environment>();

export const createDomEnvironment = (key: string) => {
  const existing = environmentMap.get(key);
  if (!existing) {
    const newEnvironment = new Environment({
      network: Network.create(fetchQuery(key)),
      store: new Store(new RecordSource()),
    });
    environmentMap.set(key, newEnvironment);
    return newEnvironment;
  }
  return existing;
};
