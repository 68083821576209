import { combineReducers } from 'redux';
import notifications from '../notifications/reducers';
import modals from '../modals/reducers';
import popovers from '../popovers/reducers';
import lookups from '../lookups/reducers';
import search from '../search/reducers';

const commonReducer = combineReducers({
  notifications,
  modals,
  popovers,
  lookups,
  search,
});

export default commonReducer;
