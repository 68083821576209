export const namespace = 'HOMEPAGE-';
const ns = (name) => `${namespace}/${name}`;

export const SET_CORE_PROSPECT_SELECTED = ns('SET_CORE_PROSPECT_SELECTED');
export const DOWNLOAD_DATA_DICTIONARY = ns('DOWNLOAD_DATA_DICTIONARY');
export const INVITE_MORE = ns('INVITE_MORE');

export const UPDATE_USER_FIRST_TIME_LOGIN_REQUEST = ns(
  'UPDATE_USER_FIRST_TIME_LOGIN_REQUEST'
);
export const UPDATE_USER_FIRST_TIME_LOGIN_SUCCESS = ns(
  'UPDATE_USER_FIRST_TIME_LOGIN_SUCCESS'
);
export const UPDATE_USER_FIRST_TIME_LOGIN_FAILED = ns(
  'UPDATE_USER_FIRST_TIME_LOGIN_FAILED'
);
