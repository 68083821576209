import { Theme, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import React from 'react';

const useStyles = makeStyles((theme: Theme) => ({
  cardContainer: {
    width: 203,
    height: 180,
    borderRadius: 6,
    border: `2px solid ${theme.palette.primary.main}`,
    overflow: 'hidden',
    cursor: 'pointer',
    background: '#fff',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  icon: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  title: {
    fontSize: 14,
    fontWeight: 700,
    marginTop: 5,
    marginLeft: 20,
    marginRight: 20,
    textAlign: 'center',
  },
}));

export const NoDashboardsCard: React.FC<{ className?: string }> = ({
  className,
}) => {
  const classes = useStyles();

  return (
    <div className={`${classes.cardContainer} ${className}`}>
      <i className={`icon-ico-dashboards ${classes.icon}`} />
      <Typography className={classes.title} color='primary' variant='h5'>
        No dashboards available yet
      </Typography>
    </div>
  );
};

NoDashboardsCard.displayName = 'NoDashboardsCard';
